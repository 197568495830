import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const DataPlatformPoints = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className="flex flex-col justify-center items-center bg-white">
			<Helmet>
				<title>
					Comprehensive Business Intelligence Services for Informed Decision
					Making | Our Data Platform Services
				</title>
				<meta
					name="description"
					content=" Our experienced professionals provide customized BI solutions for data analysis, visualization, warehousing, and reporting to help businesses improve efficiency, reduce costs, and increase revenue. Contact us to learn more about our data platform services."
				/>
			</Helmet>
			<div className="w-full h-96 overflow-hidden">
				<img
					src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2FData07.jpg?alt=media&token=7d168190-8b32-4252-afdc-7517ddf4d908"
					alt="m"
					className="px-2 w-full "
				/>
			</div>
			<div className=" w-[70%]">
				<div className="mt-16">
					<p className="text-5xl font-bold text-purple-800 mb-16 text-center">
						Our key services in Data Platform
					</p>
				</div>
				<div>
					<ul className="text-justify text-gray-600 list-disc px-8">
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Data Analysis
							</span>
							<span>:</span>
							<p className=" text-lg mt-2">
								Our team of BI experts uses advanced analytical tools to analyze
								large volumes of data and extract insights that help businesses
								make informed decisions.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Data Visualization
							</span>
							<span>:</span>
							<p className="mt-2 text-lg ">
								We use BI tools to create visual representations of data, such
								as charts, graphs, and dashboards, that help businesses
								understand complex data and identify trends and patterns.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Data Warehousing
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								Our data warehousing solutions help businesses store, organize,
								and manage their data in a centralized repository, making it
								easier to access and analyze.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Reporting
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								We create customized reports that provide businesses with
								actionable insights and help them track key performance
								indicators (KPIs) and metrics.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Consulting
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								We provide consulting services to help businesses develop BI
								strategies and implement solutions that align with their goals
								and objectives
							</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default DataPlatformPoints;
