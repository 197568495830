import React from "react";
import BlogSlide from "../../components/blogSlide/BlogSlide";
import Services from "../../components/services/Services";
import VideoComponent from "../../components/videoComponent/VideoComponent";
import SlideNew from "../../slide/SlideNew";
import Partners from "../../components/partners/Partners";
import Banner from "../../components/banner/Banner";
import { Element } from "react-scroll";

const Home = () => {
	// useEffect(() => {
	// 	scrollToDiv();
	// }, []);

	// function scrollToDiv() {
	// 	const hash = window.location.hash;
	// 	console.log("hash on home is", hash);
	// 	// const section = document.querySelector(hash);
	// 	// // if (section) {
	// 	// // 	const rect = section.getBoundingClientRect();
	// 	// // 	const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
	// 	// // 	if (!isVisible) {
	// 	// // 		section.scrollIntoView({ behavior: "smooth", block: "start" });
	// 	// // 	}
	// 	// // }
	// 	// if (section) {
	// 	// 	section.scrollIntoView({ behavior: "smooth", block: "start" });
	// 	// 	// const location = section.getBoundingClientRect().top + window.pageYOffset;
	// 	// 	// window.scrollTo({ top: location, behavior: "smooth" });
	// 	// 	//window.location.reload();
	// 	// }
	// }

	return (
		<div className="bg-white overflow-hidden ">
			<SlideNew />
			<Element id="bannerId">
				<Banner />
			</Element>
			<VideoComponent />
			<Services />
			<BlogSlide />
			<Partners />
		</div>
	);
};

export default Home;
