import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const Visualisation = () => {
	const navigate = useNavigate();
	return (
		<div className="mt-24 px-4" id="visualD">
			<Helmet>
				<title>Data Visualization and Reporting Services | MiraiTech</title>
				<meta
					name="description"
					content="MiraiTech offers customized data visualization and reporting solutions to help businesses gain insights from their data. Our services include data exploration and analysis, dashboard design and development, report creation and delivery, tool selection and implementation, and user training and support. Contact us to learn more."
				/>
			</Helmet>
			<div className="md:grid md:grid-cols-2 md:gap-6 md:h-[800px] ">
				<div className="hidden md:block">
					<div className="flex justify-center items-center">
						<img
							src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2Fdata15.jpg?alt=media&token=f590fc0c-04b9-4c32-b2a7-dd339847f248"
							alt="Web Design"
							className="h-[700px] w-[600px] mt-4"
						/>
					</div>
				</div>
				<div>
					<div className=" flex items-center justify-start  h-[80px] ">
						<p className="text-3xl md:text-5xl font-bold text-purple-800  text-center md:text-left">
							Visualisation and Reporting
						</p>
					</div>
					<div className="px-2 md:px-0 md:pr-10">
						<ul className="text-justify text-black">
							<li className="text-xl font-thin mb-1 ">
								At MiraiTech, we offer expert data visualization and reporting
								services to help our customers gain insights and value from
								their data. Our experienced team of data visualization and
								reporting specialists works closely with customers to develop
								and implement customized data visualization and reporting
								solutions that are tailored to their specific needs and
								requirements.
							</li>
							<li className="text-xl font-thin mb-1 ">
								We begin by conducting a thorough analysis of the customer's
								data sources and usage to identify areas where data
								visualization and reporting can provide the greatest value.
								Based on this analysis, we develop and implement customized data
								visualization and reporting solutions that are designed to help
								customers gain insights into their data and make informed
								business decisions.
							</li>
						</ul>
					</div>
					<p className="text-2xl md:text-3xl font-medium mt-4 px-2   mb-3 text-purple-800  ">
						Key services we provide
					</p>

					<ul className="list-disc ml-[35px] md:ml-[30px] mt-4 text-xl text-black">
						<li className=" mb-2">
							<span
								className="cursor-pointer hover:text-purple-800  hover:text-lg"
								onClick={() => navigate("/ourservices/visualisation")}
							>
								Data exploration and analysis
							</span>
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/visualisation")}
						>
							Dashboard design and development
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/visualisation")}
						>
							Report creation and delivery
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/visualisation")}
						>
							Data visualization tool selection and implementation
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/visualisation")}
						>
							User training and support
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Visualisation;
