import React from "react";

const PrivacyAndSecurity = () => {
	return (
		<div className="  ">
			<div className="flex justify-center items-center bg-transparent pb-10 ">
				<div className="w-[65%]   p-4 rounded-md">
					<h1 className="text-3xl font-bold text-center mt-10 mb-16">
						Privacy and Security
					</h1>
					<div>
						<p className="text-lg mb-8">
							Privacy and Security measures that are relevant for our website
							and company
						</p>
						<div>
							<p className="text-base mb-4">Privacy :</p>
							<ul className="flex flex-col gap-4 list-decimal ml-6">
								<li>
									<span>Data collection and storage:</span>
									<p>
										As a web design and development company, we may need to
										collect and store user data to provide certain services such
										as contact forms or newsletter subscriptions. It's important
										to ensure that this data is collected only when necessary,
										stored securely, and deleted when no longer needed. We may
										also need to comply with data protection regulations such as
										GDPR or CCPA, which may require additional measures such as
										obtaining explicit consent from users or providing
										mechanisms for data access and deletion requests.
									</p>
								</li>
								<li>
									<span>Privacy policy:</span>
									<p>
										A clear and comprehensive privacy policy is essential to
										inform users about what data we collect, how it is used, and
										how it is protected. The privacy policy should be easily
										accessible on our website and include information such as
										the types of data collected, the purpose of collection, who
										has access to the data, and how users can request access or
										deletion of their data.
									</p>
								</li>
								<li>
									<span>User rights: </span>
									<p>
										{" "}
										Users have the right to control their personal data and how
										it is used. This includes the right to access, correct, or
										delete their data. We may need to provide mechanisms for
										users to exercise these rights, such as contact forms or
										user account settings.
									</p>
								</li>
								<li>
									<span>Data sharing:</span>
									<p>
										We should be transparent about how user data is shared and
										with whom. Unless it is necessary for a specific service, we
										should not share or sell user data with third-party
										companies without obtaining explicit consent from users. We
										may also need to comply with data protection regulations
										that require additional safeguards for data sharing.
									</p>
								</li>
							</ul>
						</div>
						<div className="mt-12 ">
							<p className="text-base mb-4">Security :</p>
							<ul className="flex flex-col gap-4 list-decimal ml-6">
								<li>
									<span>SSL encryption:</span>
									<p>
										Secure Sockets Layer (SSL) encryption is essential for
										ensuring that communication between your website and its
										users is encrypted and secure. This is especially important
										for transactions that involve sensitive data such as credit
										card details or login credentials.
									</p>
								</li>
								<li>
									<span>Account security: </span>
									<p>
										If our website includes user accounts, it's important to
										implement secure login systems and strong password policies
										to prevent unauthorized access. This includes measures such
										as requiring strong passwords, limiting login attempts, and
										providing mechanisms for resetting forgotten passwords.
									</p>
								</li>
								<li>
									<span>Website security: </span>
									<p>
										Regular monitoring of your website for potential security
										vulnerabilities and applying updates and patches as needed
										is essential for maintaining website security. This includes
										measures such as installing security plugins, configuring
										firewalls, and using anti-virus software.
									</p>
								</li>
								<li>
									<span>Internal security: </span>
									<p>
										Protecting your company's internal systems and client data
										is also important. This includes measures such as
										implementing access controls, using secure storage and
										backup systems, and training employees on security best
										practices.
									</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PrivacyAndSecurity;
