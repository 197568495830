import React from "react";
import { useEffect } from "react";

const WebDevelopmentPoints = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="flex flex-col justify-center items-center bg-white">
			<div className="w-full ">
				<img
					src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2FMy%20project0001.jpg?alt=media&token=341ca441-16be-440f-9496-df2b0ca49be6"
					alt="m"
					className="px-2"
				/>
			</div>
			<div className="w-[97%] md:w-[70%]">
				<div className="mt-8 md:mt-16">
					<p className="text-4xl md:text-5xl font-bold text-purple-800 mb-16 text-center">
						Methodologies
					</p>
				</div>
				<div>
					<ul className="text-justify text-gray-600 list-disc px-8">
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold">
								Planning
							</span>
							<span>:</span>
							<p className=" text-lg mt-2">
								This involves defining the project requirements and objectives,
								identifying the target audience, and creating a project plan
								that outlines the scope, timeline, and budget. In this stage,
								the project team may conduct research and gather feedback from
								stakeholders.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold">
								Design
							</span>
							<span>:</span>
							<p className="mt-2 text-lg ">
								This involves creating wireframes, mock-ups, or prototypes of
								the website or web application. The design should be
								user-centred, visually appealing, and consistent with the brand
								identity.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold">
								Development
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								This involves coding and programming the website or web
								application. The developers create the front-end user interface
								using HTML, CSS, and JavaScript, and the back-end functionality
								using programming languages such as PHP, Python, or Ruby.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold">
								Testing
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								This involves testing the website or web application for
								functionality, performance, security, and usability. The project
								team may use automated testing tools or conduct manual testing
								to ensure that the site is error-free and meets the project
								requirements.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold">
								Deployment
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								This involves uploading the website or web application to a web
								server and making it accessible to users. The project team may
								also configure domain name, SSL certificates, and other settings
								to ensure the website is secure and properly optimized for
								search engines.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold">
								Maintenance
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								This involves ongoing maintenance and updates to ensure that the
								website or web application is secure, up-to-date, and free of
								errors. This may include regular backups, security patches,
								software updates, and content updates.
							</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default WebDevelopmentPoints;
