import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Services.css";

const Services = () => {
	const [one, setOne] = useState(false);
	const [two, setTwo] = useState(false);
	const [three, setThree] = useState(false);
	const [four, setFour] = useState(false);
	const [five, setFive] = useState(false);
	const [six, setSix] = useState(false);
	const [seven, setSeven] = useState(false);
	const [eight, setEight] = useState(false);

	const navigate = useNavigate();

	return (
		<div className="  flex flex-col justify-center items-center mb-10 mt-10 py-6 ">
			<p
				className="text-3xl md:text-4xl lg:text-5xl font-medium text-center md:mb-[40px] lg:mb-[80px] "
				style={{ "font-family": "Bree Serif" }}
			>
				Transforming Tomorrow with Expert IT Services
			</p>
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-12 gap-y-12 w-[80%] xl:w-[90%]    justify-items-center content-evenly p-6 rounded-md ">
				<div
					className="w-[300px] h-[300px]  text-center cursor-pointer shadow-lg bg-white border border-gray-200 shadow-gray-200 rounded-md"
					onMouseOver={() => setOne(true)}
					onMouseOut={() => setOne(false)}
					onClick={() => navigate("/ourservices#webD")}
				>
					<div
						className={
							one === true
								? "hidden"
								: "flex flex-col gap-4 justify-start pt-[50px] items-center  w-full h-full animate-gradient rounded-md"
						}
					>
						<svg
							className="flex-shrink-0 h-16 w-16 text-indigo-600"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							aria-hidden="true"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
							/>
						</svg>
						<p
							className="text-3xl font-medium ml-2 text-white"
							style={{ "font-family": "Bree Serif" }}
						>
							Web Development
						</p>
					</div>
					<div
						className={
							one === false
								? "hidden"
								: "w-full h-full p-3 flex justify-center items-start"
						}
					>
						<p className="text-lg text-gray-600">
							<p className="mt-2 mb-6 font-bold text-xl text-black">
								Web Development
							</p>
							<ul className="list-disc list-inside text-left">
								<li>Planning</li>
								<li>Design</li>
								<li>Development</li>
								<li>Testing</li>
								<li>Deployment</li>
								<li>Maintenance</li>
							</ul>
						</p>
					</div>
				</div>
				<div
					className="w-[300px] h-[300px]  text-center cursor-pointer shadow-lg bg-white border border-gray-200 shadow-gray-200 rounded-md"
					onMouseOver={() => setTwo(true)}
					onMouseOut={() => setTwo(false)}
					onClick={() => navigate("/ourservices#dataA")}
				>
					<div
						className={
							two === true
								? "hidden"
								: "flex flex-col gap-4 justify-start pt-[50px] items-center  w-full h-full animate-gradient "
						}
					>
						<svg
							className="flex-shrink-0 h-16 w-16 text-indigo-600"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke-width="1.5"
							stroke="currentColor"
							aria-hidden="true"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
							/>
						</svg>
						<p
							className="text-3xl font-medium ml-2 text-white"
							style={{ "font-family": "Bree Serif" }}
						>
							Data Analytics
						</p>
					</div>
					<div
						className={
							two === false
								? "hidden"
								: "w-full h-full p-3 flex justify-center items-start"
						}
					>
						<p className="text-lg text-gray-600">
							<p className="mt-2 mb-6 font-bold text-xl text-black">
								Data Analytics
							</p>
							<ul className="list-disc list-inside text-left">
								<li>Data Management</li>
								<li>Data Modelling</li>
								<li>Descriptive Analytics</li>
								<li>Diagnostic Analytics</li>
								<li>Prescriptive Analytics</li>
							</ul>
						</p>
					</div>
				</div>
				<div
					className="w-[300px] h-[300px]  text-center cursor-pointer shadow-lg bg-white border border-gray-200 shadow-gray-200 rounded-md"
					onMouseOver={() => setThree(true)}
					onMouseOut={() => setThree(false)}
					onClick={() => navigate("/ourservices#dbaS")}
				>
					<div
						className={
							three === true
								? "hidden"
								: "flex flex-col gap-4 justify-start pt-[50px] items-center  w-full h-full animate-gradient"
						}
					>
						<svg
							className="flex-shrink-0 h-16 w-16 text-indigo-600"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke-width="1.5"
							stroke="currentColor"
							aria-hidden="true"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
							/>
						</svg>
						<p
							className="text-3xl font-medium ml-2 text-white"
							style={{ "font-family": "Bree Serif" }}
						>
							DBA Support
						</p>
					</div>
					<div
						className={
							three === false
								? "hidden"
								: "w-full h-full p-3 flex justify-center items-start"
						}
					>
						<p className="text-lg text-gray-600">
							<p className="mt-2 mb-6 font-bold text-xl text-black">
								Database administration & Support
							</p>
							<ul className="list-disc list-inside text-left">
								<li>Database Design and Implementation:</li>
								<li>Database Management</li>
								<li>Security and Access Contro</li>
								<li>Backup and Recovery</li>
								<li>Database Tuning</li>
							</ul>
						</p>
					</div>
				</div>
				<div
					className="w-[300px] h-[300px]  text-center cursor-pointer shadow-lg bg-white border border-gray-200 shadow-gray-200 rounded-md"
					onMouseOver={() => setFour(true)}
					onMouseOut={() => setFour(false)}
					onClick={() => navigate("/ourservices#visualD")}
				>
					<div
						className={
							four === true
								? "hidden"
								: "flex flex-col gap-4 justify-start pt-[50px] items-center  w-full h-full animate-gradient "
						}
					>
						<svg
							className="flex-shrink-0 h-16 w-16 text-indigo-600"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke-width="1.5"
							stroke="currentColor"
							aria-hidden="true"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605"
							/>
						</svg>
						<p
							className="text-3xl font-medium ml-2 text-white"
							style={{ "font-family": "Bree Serif" }}
						>
							Visualisation and Reporting
						</p>
					</div>
					<div
						className={
							four === false
								? "hidden"
								: "w-full h-full p-3 flex justify-center items-start"
						}
					>
						<p className="text-lg text-gray-600">
							<p className="mt-2 mb-6 font-bold text-xl text-black">
								Data Visualisation and Reporting
							</p>
							<ul className="list-disc list-inside text-left">
								<li>Data exploration and analysis</li>
								<li>Dashboard design and development</li>
								<li>Report creation and delivery</li>
								<li>Data visualization tool selection and implementation</li>
								<li>User training and support</li>
							</ul>
						</p>
					</div>
				</div>
				<div
					className="w-[300px] h-[300px]  text-center cursor-pointer shadow-lg bg-white border border-gray-200 shadow-gray-200 rounded-md"
					onMouseOver={() => setFive(true)}
					onMouseOut={() => setFive(false)}
					onClick={() => navigate("/ourservices#digitalM")}
				>
					<div
						className={
							five === true
								? "hidden"
								: "flex flex-col gap-4 justify-start pt-[50px] items-center  w-full h-full animate-gradient "
						}
					>
						<svg
							className="flex-shrink-0 h-16 w-16 text-indigo-600"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="currentColor"
							aria-hidden="true"
						>
							<path
								fill-rule="evenodd"
								d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z"
								clip-rule="evenodd"
							/>
							<path d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z" />
						</svg>
						<p
							className="text-3xl font-medium ml-2 text-white"
							style={{ "font-family": "Bree Serif" }}
						>
							Digital Marketing
						</p>
					</div>
					<div
						className={
							five === false
								? "hidden"
								: "w-full h-full p-3 flex justify-center items-start"
						}
					>
						<p className="text-lg text-gray-600">
							<p className="mt-2 mb-6 font-bold text-xl text-black">
								Digital Marketing
							</p>
							<ul className="list-disc list-inside text-left">
								<li>Search Engine Optimization </li>
								<li>Pay-Per-Click (PPC) Advertising</li>
								<li>Social Media Marketing</li>
								<li>Content Marketing</li>
								<li>Email Marketing</li>
							</ul>
						</p>
					</div>
				</div>
				<div
					className="w-[300px] h-[300px]  text-center cursor-pointer shadow-lg bg-white border border-gray-200 shadow-gray-200 rounded-md"
					onMouseOver={() => setSix(true)}
					onMouseOut={() => setSix(false)}
					onClick={() => navigate("/ourservices#machineL")}
				>
					<div
						className={
							six === true
								? "hidden"
								: "flex flex-col gap-4 justify-start pt-[50px] items-center  w-full h-full animate-gradient"
						}
					>
						<svg
							className="flex-shrink-0 h-16 w-16 text-indigo-600"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="currentColor"
							aria-hidden="true"
						>
							<path d="M12 .75a8.25 8.25 0 00-4.135 15.39c.686.398 1.115 1.008 1.134 1.623a.75.75 0 00.577.706c.352.083.71.148 1.074.195.323.041.6-.218.6-.544v-4.661a6.714 6.714 0 01-.937-.171.75.75 0 11.374-1.453 5.261 5.261 0 002.626 0 .75.75 0 11.374 1.452 6.712 6.712 0 01-.937.172v4.66c0 .327.277.586.6.545.364-.047.722-.112 1.074-.195a.75.75 0 00.577-.706c.02-.615.448-1.225 1.134-1.623A8.25 8.25 0 0012 .75z" />
							<path
								fill-rule="evenodd"
								d="M9.013 19.9a.75.75 0 01.877-.597 11.319 11.319 0 004.22 0 .75.75 0 11.28 1.473 12.819 12.819 0 01-4.78 0 .75.75 0 01-.597-.876zM9.754 22.344a.75.75 0 01.824-.668 13.682 13.682 0 002.844 0 .75.75 0 11.156 1.492 15.156 15.156 0 01-3.156 0 .75.75 0 01-.668-.824z"
								clip-rule="evenodd"
							/>
						</svg>
						<p
							className="text-3xl font-medium ml-2 text-white"
							style={{ "font-family": "Bree Serif" }}
						>
							Machine Learning
						</p>
					</div>
					<div
						className={
							six === false
								? "hidden"
								: "w-full h-full p-3 flex justify-center items-start"
						}
					>
						<p className="text-lg text-gray-600">
							<p className="mt-2 mb-6 font-bold text-xl text-black">
								Machine Learning
							</p>
							<ul className="list-disc list-inside text-left">
								<li>Data Analysis</li>
								<li>Predictive Modelling</li>
								<li>Natural Language Processing</li>
								<li>Image Recognition</li>
								<li>Deep Learning</li>
							</ul>
						</p>
					</div>
				</div>
				<div
					className="w-[300px] h-[300px]  text-center cursor-pointer shadow-lg bg-white border border-gray-200 shadow-gray-200 rounded-md"
					onMouseOver={() => setSeven(true)}
					onMouseOut={() => setSeven(false)}
					onClick={() => navigate("/ourservices#dataP")}
				>
					<div
						className={
							seven === true
								? "hidden"
								: "flex flex-col gap-4 justify-start pt-[50px] items-center  w-full h-full animate-gradient "
						}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke-width="1.5"
							stroke="currentColor"
							className="flex-shrink-0 h-16 w-16 text-indigo-600"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
							/>
						</svg>

						<p
							className="text-3xl font-medium ml-2 text-white"
							style={{ "font-family": "Bree Serif" }}
						>
							Data Platform
						</p>
					</div>
					<div
						className={
							seven === false
								? "hidden"
								: "w-full h-full p-3 flex justify-center items-start"
						}
					>
						<p className="text-lg text-gray-600">
							<p className="mt-2 mb-6 font-bold text-xl text-black">
								Data Platform
							</p>
							<ul className="list-disc list-inside text-left">
								<li>Data Analysis</li>
								<li>Data Visualisation</li>
								<li>Data Warehousing</li>
								<li>Reporting</li>
								<li>Consulting</li>
							</ul>
						</p>
					</div>
				</div>
				<div
					className="w-[300px] h-[300px]  text-center cursor-pointer shadow-lg bg-white border border-gray-200 shadow-gray-200 rounded-md"
					onMouseOver={() => setEight(true)}
					onMouseOut={() => setEight(false)}
					onClick={() => navigate("/ourservices#webDes")}
				>
					<div
						className={
							eight === true
								? "hidden"
								: "flex flex-col gap-4 justify-start pt-[50px] items-center  w-full h-full animate-gradient "
						}
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke-width="1.5"
							stroke="currentColor"
							className="flex-shrink-0 h-16 w-16 text-indigo-600"
						>
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
							/>
						</svg>

						<p
							className="text-3xl font-medium ml-2 text-white"
							style={{ "font-family": "Bree Serif" }}
						>
							Web Designing
						</p>
					</div>
					<div
						className={
							eight === false
								? "hidden"
								: "w-full h-full p-3 flex justify-center items-start"
						}
					>
						<p className="text-lg text-gray-600">
							<p className="mt-2 mb-6 font-bold text-xl text-black">
								Web Designing
							</p>
							<ul className="list-disc list-inside text-left">
								<li>Planning</li>
								<li>Wireframing</li>
								<li>Prototyping</li>
								<li>Visual Design</li>
								<li>Content Creation</li>
								<li>Testing</li>
								<li>Deployment</li>
							</ul>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Services;
