import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
	const navigate = useNavigate();
	return (
		<div className="bg-white pt-24 flex justify-center">
			<div className="w-[100%] h-[30%] lg:h-[25rem] bg-black flex flex-col justify-center items-center  ">
				<div className=" lg:h-[19rem] lg:w-[70%] flex flex-col lg:flex-row justify-evenly  w-[90%]">
					<div className="flex flex-col justify-center items-start mt-4 md:mt-8 lg:-mt-10">
						<div class="border-2 border-purple-400 w-[40px] lg:w-[50px] "></div>
						<p
							className="text-3xl lg:text-4xl text-white mb-[20px] md:text-4xl cursor-pointer hover:text-purple-600   lg:mb-[50px]"
							style={{ "font-family": "Bree Serif" }}
							onClick={() => navigate("/contactus")}
						>
							CONTACT US
						</p>
						<p
							className="text-2xl  text-white "
							style={{ "font-family": "Bree Serif" }}
						>
							we would love to hear
							<br className="hidden lg:block" />
							<span className="pl-2 lg:pl-0">from you.</span>
						</p>
					</div>
					<div class="hidden lg:block border border-gray-400 h-[90%] "></div>
					<div className="flex flex-col justify-center items-start  gap-8 mt-6 xl:mt-0">
						<div className="flex md:flex-row flex-col justify-center items-start lg:items-center gap-2 md:gap-4">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								className="flex-shrink-0 md:h-10 md:w-10 h-6 w-6 text-purple-600"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
								/>
							</svg>
							<p
								className="text-white text-xl md:text-2xl md:underline md:underline-offset-4 md:decoration-purple-800"
								style={{ "font-family": "Bree Serif" }}
							>
								sales@miraitech.com.au
							</p>
						</div>
						<div className="flex justify-center items-start lg:items-center gap-4">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								className="flex-shrink-0 md:h-10 md:w-10 h-6 w-6 mt-1 lg:mt-0 text-purple-600"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
								/>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
								/>
							</svg>
							<p
								className="text-white md:text-2xl text-xl"
								style={{ "font-family": "Bree Serif" }}
							>
								Melbourne , Australia
							</p>
						</div>
						<div className="flex justify-center items-start lg:items-center gap-4">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke-width="1.5"
								stroke="currentColor"
								className="flex-shrink-0 md:h-10 md:w-10 h-6 w-6 mt-1 md:mt-0 text-purple-600"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
								/>
							</svg>

							<p
								className="text-white md:text-2xl text-xl"
								style={{ "font-family": "Bree Serif" }}
							>
								+61-419867768
							</p>
						</div>
					</div>
				</div>
				<p className=" text-gray-300 mt-10 text-right w-full pr-4">
					Copyright MiraiTech 2023. All rights reserved
				</p>
			</div>
		</div>
	);
};

export default Footer;
