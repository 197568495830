import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Link } from "react-scroll";

const Navbar = () => {
	const [open, setOpen] = React.useState(false);
	const [flyer, setFlyer] = React.useState(false);
	const [flyerTwo, setFlyerTwo] = React.useState(false);
	const navigate = useNavigate();

	return (
		<>
			{/* This example requires Tailwind CSS v2.0+ */}
			<motion.div
				className="relative bg-white  z-10 w-[100%]"
				initial={{ opacity: 0, x: "-100vw" }}
				animate={{ opacity: 1, x: 0 }}
				transition={{ duration: 2 }}
			>
				<div className="max-w-7xl lg:max-w-full  px-4  md:px-2 lg:px-12 sm:px-6  ">
					<div className="flex justify-between items-center  py-6  md:justify-center   md:space-x-12">
						<div className="flex justify-start lg:w-0 lg:flex-1">
							<NavLink to="/">
								<span
									className="text-3xl font-medium  p-2 md:ml-[-7rem] lg:ml-0 text-black tracking-[2px] "
									style={{ "font-family": "Anton" }}
								>
									Mirai<span className="text-purple-800">Tech</span>
								</span>
							</NavLink>
						</div>
						<div className="-mr-2 -my-2 md:hidden">
							<button
								type="button"
								className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black"
								onClick={() => setOpen(!open)}
							>
								<svg
									className="h-6 w-6"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M4 6h16M4 12h16M4 18h16"
									/>
								</svg>
							</button>
						</div>
						<nav
							className="hidden md:flex  md:space-x-10 md:-mr-8  lg:space-x-14 xl:space-x-24  "
							style={{ fontFamily: "Bree Serif" }}
						>
							<NavLink
								to="/"
								className="text-xl font-medium text-black hover:scale-105"
							>
								Home
							</NavLink>
							<Link
								to="bannerId"
								className="text-xl font-medium text-black hover:scale-105 cursor-pointer"
							>
								About Us
							</Link>
							<div className="relative">
								<button
									type="button"
									className="
                      group  rounded-md text-black inline-flex items-center text-xl font-medium focus:outline-none focus:ring-2  focus:ring-black px-2
                     "
									onClick={() => setFlyer(!flyer)}
								>
									<span>Services</span>
									{/*
                 Heroicon name: solid/chevron-down
   
                 Item active: "text-gray-600", Item inactive: "text-gray-400"
               */}
									<svg
										className={
											flyer === true
												? "transform rotate-180 ml-2 h-5 w-5 text-black group-hover:text-black transition ease-out duration-200"
												: "transform rotate-0 transition ease-out duration-200 ml-2 h-5 w-5 text-black group-hover:text-black"
										}
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
									>
										<path
											fillRule="evenodd"
											d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</button>
								{/*
               'Solutions' flyout menu, show/hide based on flyout menu state.
   
               Entering: "transition ease-out duration-200"
                 From: "opacity-0 translate-y-1"
                 To: "opacity-100 translate-y-0"
               Leaving: "transition ease-in duration-150"
                 From: "opacity-100 translate-y-0"
                 To: "opacity-0 translate-y-1"
             */}

								<div
									onMouseLeave={() => setFlyer(false)}
									className={
										flyer
											? " opacity-100 translate-y-0 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-[60%] md:left-1/2 md:-translate-x-1/2"
											: " hidden translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
									}
								>
									<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
										<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
											<Link
												to="webD1"
												className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100"
												onClick={() => navigate("/ourservices#webD")}
											>
												{/* Heroicon name: outline/chart-bar */}

												<svg
													className="flex-shrink-0 h-6 w-6 text-indigo-600"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
													aria-hidden="true"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
													/>
												</svg>

												<div className="ml-4">
													<p className="text-base font-medium text-gray-900">
														Web Development
													</p>
													<p className="mt-1 text-sm text-gray-500">
														Web development that merges cutting-edge technology
														with creativity and functionality.
													</p>
												</div>
											</Link>
											<Link
												to="webDes1"
												className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100"
												onClick={() => navigate("/ourservices#webDes")}
											>
												{/* Heroicon name: outline/chart-bar */}

												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													stroke-width="1.5"
													stroke="currentColor"
													className="flex-shrink-0 h-6 w-6 text-indigo-600"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
													/>
												</svg>

												<div className="ml-4">
													<p className="text-base font-medium text-gray-900">
														Web Designing
													</p>
													<p className="mt-1 text-sm text-gray-500">
														We bring your vision to life with design.
													</p>
												</div>
											</Link>
											<Link
												to="dataA1"
												className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100"
												onClick={() => navigate("/ourservices#dataA")}
											>
												<svg
													className="flex-shrink-0 h-6 w-6 text-indigo-600"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													stroke-width="1.5"
													stroke="currentColor"
													aria-hidden="true"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
													/>
												</svg>

												<div className="ml-4">
													<p className="text-base font-medium text-gray-900">
														Data analytics
													</p>
													<p className="mt-1 text-sm text-gray-500">
														Uncovering insights, powering decisions.
													</p>
												</div>
											</Link>
											<Link
												to="dbaS1"
												className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100"
												onClick={() => navigate("/ourservices#dbaS")}
											>
												<svg
													className="flex-shrink-0 h-6 w-6 text-indigo-600"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													stroke-width="1.5"
													stroke="currentColor"
													aria-hidden="true"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
													/>
												</svg>

												<div className="ml-4">
													<p className="text-base font-medium text-gray-900">
														Database administration & Support
													</p>
													<p className="mt-1 text-sm text-gray-500">
														Optimizing performance, securing data.
													</p>
												</div>
											</Link>
											<Link
												to="dataP1"
												className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100"
												onClick={() => navigate("/ourservices#dataP")}
											>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													stroke-width="1.5"
													stroke="currentColor"
													className="flex-shrink-0 h-6 w-6 text-indigo-600"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
													/>
												</svg>

												<div className="ml-4">
													<p className="text-base font-medium text-gray-900">
														Data Platform
													</p>
													<p className="mt-1 text-sm text-gray-500">
														Optimizing performance, securing data.
													</p>
												</div>
											</Link>
											<Link
												to="visualD1"
												className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100"
												onClick={() => navigate("/ourservices#visualD")}
											>
												{/* Heroicon name: outline/view-grid */}

												<svg
													className="flex-shrink-0 h-6 w-6 text-indigo-600"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													stroke-width="1.5"
													stroke="currentColor"
													aria-hidden="true"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605"
													/>
												</svg>

												<div className="ml-4">
													<p className="text-base font-medium text-gray-900">
														Data Visualisation and Reporting
													</p>
													<p className="mt-1 text-sm text-gray-500">
														Transforming data into insights, driving informed
														decisions.
													</p>
												</div>
											</Link>
											<Link
												to="digitalM1"
												className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100"
												onClick={() => navigate("/ourservices#digitalM")}
											>
												<svg
													className="flex-shrink-0 h-6 w-6 text-indigo-600"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													fill="currentColor"
													aria-hidden="true"
												>
													<path
														fill-rule="evenodd"
														d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z"
														clip-rule="evenodd"
													/>
													<path d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z" />
												</svg>

												<div className="ml-4">
													<p className="text-base font-medium text-gray-900">
														Digital Marketing
													</p>
													<p className="mt-1 text-sm text-gray-500">
														Transforming online presence, maximizing customer
														engagement.
													</p>
												</div>
											</Link>
											<Link
												to="machineL1"
												className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100"
												onClick={() => navigate("/ourservices#machineL")}
											>
												<svg
													className="flex-shrink-0 h-6 w-6 text-indigo-600"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													fill="currentColor"
													aria-hidden="true"
												>
													<path d="M12 .75a8.25 8.25 0 00-4.135 15.39c.686.398 1.115 1.008 1.134 1.623a.75.75 0 00.577.706c.352.083.71.148 1.074.195.323.041.6-.218.6-.544v-4.661a6.714 6.714 0 01-.937-.171.75.75 0 11.374-1.453 5.261 5.261 0 002.626 0 .75.75 0 11.374 1.452 6.712 6.712 0 01-.937.172v4.66c0 .327.277.586.6.545.364-.047.722-.112 1.074-.195a.75.75 0 00.577-.706c.02-.615.448-1.225 1.134-1.623A8.25 8.25 0 0012 .75z" />
													<path
														fill-rule="evenodd"
														d="M9.013 19.9a.75.75 0 01.877-.597 11.319 11.319 0 004.22 0 .75.75 0 11.28 1.473 12.819 12.819 0 01-4.78 0 .75.75 0 01-.597-.876zM9.754 22.344a.75.75 0 01.824-.668 13.682 13.682 0 002.844 0 .75.75 0 11.156 1.492 15.156 15.156 0 01-3.156 0 .75.75 0 01-.668-.824z"
														clip-rule="evenodd"
													/>
												</svg>

												<div className="ml-4">
													<p className="text-base font-medium text-gray-900">
														Machine Learning
													</p>
													<p className="mt-1 text-sm text-gray-500">
														Leveraging technology, driving innovation.
													</p>
												</div>
											</Link>
											<NavLink
												to="/ourservices"
												className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100"
											>
												{/* <svg
													className="flex-shrink-0 h-6 w-6 text-indigo-600"
													xmlns="http://www.w3.org/2000/svg"
													viewBox="0 0 24 24"
													fill="currentColor"
													aria-hidden="true"
												>
													<path d="M12 .75a8.25 8.25 0 00-4.135 15.39c.686.398 1.115 1.008 1.134 1.623a.75.75 0 00.577.706c.352.083.71.148 1.074.195.323.041.6-.218.6-.544v-4.661a6.714 6.714 0 01-.937-.171.75.75 0 11.374-1.453 5.261 5.261 0 002.626 0 .75.75 0 11.374 1.452 6.712 6.712 0 01-.937.172v4.66c0 .327.277.586.6.545.364-.047.722-.112 1.074-.195a.75.75 0 00.577-.706c.02-.615.448-1.225 1.134-1.623A8.25 8.25 0 0012 .75z" />
													<path
														fill-rule="evenodd"
														d="M9.013 19.9a.75.75 0 01.877-.597 11.319 11.319 0 004.22 0 .75.75 0 11.28 1.473 12.819 12.819 0 01-4.78 0 .75.75 0 01-.597-.876zM9.754 22.344a.75.75 0 01.824-.668 13.682 13.682 0 002.844 0 .75.75 0 11.156 1.492 15.156 15.156 0 01-3.156 0 .75.75 0 01-.668-.824z"
														clip-rule="evenodd"
													/>
												</svg> */}

												<div className="ml-4">
													<p className="text-base font-medium text-gray-900 underline">
														ALL SERVICES
													</p>
													<p className="mt-1 text-sm text-gray-500"></p>
												</div>
											</NavLink>
										</div>
									</div>
								</div>
							</div>

							<div className="relative">
								<button
									type="button"
									className="group  rounded-md text-black inline-flex items-center text-xl font-medium  focus:outline-none focus:ring-2  focus:ring-black px-2"
									onClick={() => setFlyerTwo(!flyerTwo)}
								>
									<span>More</span>
									{/*
                 Heroicon name: solid/chevron-down
   
                 Item active: "text-gray-600", Item inactive: "text-gray-400"
               */}
									<svg
										className={
											flyerTwo === true
												? "transform rotate-180 ml-2 h-5 w-5 text-black group-hover:text-black transition ease-out duration-200"
												: "ml-2 h-5 w-5 text-black group-hover:text-black"
										}
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
									>
										<path
											fillRule="evenodd"
											d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
											clipRule="evenodd"
										/>
									</svg>
								</button>
								{/*
               'More' flyout menu, show/hide based on flyout menu state.
   
               Entering: "transition ease-out duration-200"
                 From: "opacity-0 translate-y-1"
                 To: "opacity-100 translate-y-0"
               Leaving: "transition ease-in duration-150"
                 From: "opacity-100 translate-y-0"
                 To: "opacity-0 translate-y-1"
             */}{" "}
								<div
									onMouseLeave={() => setFlyerTwo(false)}
									className={
										flyerTwo
											? " opacity-100 translate-y-0 transition ease-out duration-200 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-[90%] md:left-1/3 md:-translate-x-1/2"
											: " hidden translate-y-1 absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
									}
								>
									<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
										<div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
											<NavLink
												to="/contactus"
												className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100"
											>
												{/* Heroicon name: outline/support */}
												<svg
													className="flex-shrink-0 h-6 w-6 text-indigo-600"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
													aria-hidden="true"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth={2}
														d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
													/>
												</svg>
												<div className="ml-4">
													<p className="text-base font-medium text-gray-900 cursor-pointer">
														Contact Us
													</p>
													<p className="mt-1 text-sm text-gray-500">
														Get all of your questions answered in our contact
														support.
													</p>
												</div>
											</NavLink>

											<NavLink
												to="/privacyandsecurity"
												className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-100"
											>
												{/* Heroicon name: outline/shield-check */}
												<svg
													className="flex-shrink-0 h-6 w-6 text-indigo-600"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													stroke="currentColor"
													aria-hidden="true"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth={2}
														d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
													/>
												</svg>
												<div className="ml-4  ">
													<p className="text-base font-medium text-gray-900">
														Privacy and Security
													</p>
													<p className="mt-1 text-sm text-gray-500">
														Understand how we take your privacy seriously.
													</p>
												</div>
											</NavLink>
										</div>
										<div className="px-5 py-5 bg-gray-50 sm:px-8 sm:py-8">
											<div>
												<h3 className="text-sm tracking-wide font-medium text-gray-500 uppercase">
													Recent Posts
												</h3>
												<ul className="mt-4 space-y-4">
													<li className="text-base truncate">
														<NavLink
															to="/blog/reactisbest"
															className="font-medium text-gray-900 hover:text-gray-700"
														>
															Why React is best ?
														</NavLink>
													</li>
													<li className="text-base truncate">
														<NavLink
															to="/blog/needofseo"
															className="font-medium text-gray-900 hover:text-gray-700"
														>
															Why do you need SEO ?
														</NavLink>
													</li>
													<li className="text-base truncate">
														<NavLink
															to="/blog/benefitsofdatananlysis"
															className="font-medium text-gray-900 hover:text-gray-700"
														>
															What are the benefits of Data Analysis ?
														</NavLink>
													</li>
													<li className="text-base truncate">
														<NavLink
															to="/blog/artificialintelligence"
															className="font-medium text-gray-900 hover:text-gray-700"
														>
															What is Artificial Intelligence and its current
															scenario ?
														</NavLink>
													</li>
													<li className="text-base truncate">
														<NavLink
															to="/blog/digitaimarketingbenefits"
															className="font-medium text-gray-900 hover:text-gray-700"
														>
															How digital marketing can help your business grow
															?
														</NavLink>
													</li>
													<li className="text-base truncate">
														<NavLink
															to="/blog/machinelearningbenefits"
															className="font-medium text-gray-900 hover:text-gray-700"
														>
															Machine learning and its benefit for your business
														</NavLink>
													</li>
												</ul>
											</div>
											{/* <div className="mt-5 text-sm">
												<NavLink
													to="/"
													className="font-medium text-indigo-600 hover:text-indigo-500"
												>
													{" "}
													View all posts <span aria-hidden="true">→</span>
												</NavLink>
											</div> */}
										</div>
									</div>
								</div>
							</div>
						</nav>
					</div>
				</div>
				{/*
       Mobile menu, show/hide based on mobile menu state.
   
       Entering: "duration-200 ease-out"
         From: ""
         To: ""
       Leaving: "duration-100 ease-in"
         From: "opacity-100 scale-100"
         To: "opacity-0 scale-95"
     */}

				<div
					className={
						open
							? "opacity-100 scale-100 transition ease-out duration-200 absolute top-0 left-6 p-2  transform origin-top-right md:hidden"
							: "hidden scale-95 absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
					}
				>
					<div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2  divide-gray-50">
						<div className="pt-5 pb-6 px-5">
							<div className="flex items-center justify-between">
								<div>
									<span
										className="text-xl font-bold   p-2  text-gray-700 tracking-[2px]"
										style={{ "font-family": "Anton" }}
									>
										Mirai<span className="text-purple-800">Tech</span>
									</span>
								</div>
								<div className="-mr-2">
									<button
										type="button"
										className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
										onClick={() => setOpen(!open)}
									>
										<span className="sr-only">Close menu</span>
										{/* Heroicon name: outline/x */}
										<svg
											className="h-6 w-6"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											aria-hidden="true"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M6 18L18 6M6 6l12 12"
											/>
										</svg>
									</button>
								</div>
							</div>
							<div className="mt-6">
								<nav className="grid gap-y-8">
									<NavLink
										to="/ourservices#webD"
										className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
										onClick={() => setOpen(false)}
									>
										{/* Heroicon name: outline/chart-bar */}
										<svg
											className="flex-shrink-0 h-6 w-6 text-indigo-600"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											aria-hidden="true"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
											/>
										</svg>
										<span className="ml-3 text-base font-medium text-gray-900">
											Web Development
										</span>
									</NavLink>
									<NavLink
										to="/ourservices#webDes"
										className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
										onClick={() => setOpen(false)}
									>
										{/* Heroicon name: outline/chart-bar */}
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke-width="1.5"
											stroke="currentColor"
											className="flex-shrink-0 h-6 w-6 text-indigo-600"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
											/>
										</svg>
										<span className="ml-3 text-base font-medium text-gray-900">
											Web Designing
										</span>
									</NavLink>
									<NavLink
										to="/ourservices#dataA"
										className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
										onClick={() => setOpen(false)}
									>
										{/* Heroicon name: outline/cursor-click */}
										<svg
											className="flex-shrink-0 h-6 w-6 text-indigo-600"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke-width="1.5"
											stroke="currentColor"
											aria-hidden="true"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
											/>
										</svg>
										<span className="ml-3 text-base font-medium text-gray-900">
											Data Analytics
										</span>
									</NavLink>
									<NavLink
										to="/ourservices#dbaS"
										className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
										onClick={() => setOpen(false)}
									>
										{/* Heroicon name: outline/shield-check */}
										<svg
											className="flex-shrink-0 h-6 w-6 text-indigo-600"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke-width="1.5"
											stroke="currentColor"
											aria-hidden="true"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
											/>
										</svg>
										<span className="ml-3 text-base font-medium text-gray-900">
											Data base administration & Support
										</span>
									</NavLink>
									<NavLink
										to="/ourservices#visualD"
										className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
										onClick={() => setOpen(false)}
									>
										{/* Heroicon name: outline/view-grid */}
										<svg
											className="flex-shrink-0 h-6 w-6 text-indigo-600"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke-width="1.5"
											stroke="currentColor"
											aria-hidden="true"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605"
											/>
										</svg>
										<span className="ml-3 text-base font-medium text-gray-900">
											Data Visualisation and Reporting
										</span>
									</NavLink>
									<NavLink
										to="/ourservices#dataP"
										className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
										onClick={() => setOpen(false)}
									>
										{/* Heroicon name: outline/view-grid */}
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											stroke-width="1.5"
											stroke="currentColor"
											className="flex-shrink-0 h-6 w-6 text-indigo-600"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
											/>
										</svg>
										<span className="ml-3 text-base font-medium text-gray-900">
											Data Platform
										</span>
									</NavLink>
									<NavLink
										to="/ourservices#digitalM"
										className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
										onClick={() => setOpen(false)}
									>
										{/* Heroicon name: outline/refresh */}
										<svg
											className="flex-shrink-0 h-6 w-6 text-indigo-600"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											fill="currentColor"
											aria-hidden="true"
										>
											<path
												fill-rule="evenodd"
												d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z"
												clip-rule="evenodd"
											/>
											<path d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z" />
										</svg>
										<span className="ml-3 text-base font-medium text-gray-900">
											Digital Marketing
										</span>
									</NavLink>
									<NavLink
										to="/ourservices#machineL"
										className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
										onClick={() => setOpen(false)}
									>
										{/* Heroicon name: outline/refresh */}
										<svg
											className="flex-shrink-0 h-6 w-6 text-indigo-600"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 24 24"
											fill="currentColor"
											aria-hidden="true"
										>
											<path d="M12 .75a8.25 8.25 0 00-4.135 15.39c.686.398 1.115 1.008 1.134 1.623a.75.75 0 00.577.706c.352.083.71.148 1.074.195.323.041.6-.218.6-.544v-4.661a6.714 6.714 0 01-.937-.171.75.75 0 11.374-1.453 5.261 5.261 0 002.626 0 .75.75 0 11.374 1.452 6.712 6.712 0 01-.937.172v4.66c0 .327.277.586.6.545.364-.047.722-.112 1.074-.195a.75.75 0 00.577-.706c.02-.615.448-1.225 1.134-1.623A8.25 8.25 0 0012 .75z" />
											<path
												fill-rule="evenodd"
												d="M9.013 19.9a.75.75 0 01.877-.597 11.319 11.319 0 004.22 0 .75.75 0 11.28 1.473 12.819 12.819 0 01-4.78 0 .75.75 0 01-.597-.876zM9.754 22.344a.75.75 0 01.824-.668 13.682 13.682 0 002.844 0 .75.75 0 11.156 1.492 15.156 15.156 0 01-3.156 0 .75.75 0 01-.668-.824z"
												clip-rule="evenodd"
											/>
										</svg>
										<span className="ml-3 text-base font-medium text-gray-900">
											Machine Learning
										</span>
									</NavLink>
								</nav>
							</div>
						</div>
						<div className="py-6 px-5 space-y-6">
							<div className="grid grid-cols-3 gap-y-4 gap-x-8">
								<NavLink
									to="/"
									className="text-base font-medium text-gray-900 hover:text-gray-700"
									onClick={() => setOpen(false)}
								>
									Home
								</NavLink>
								<NavLink
									to="/"
									className="text-base font-medium text-gray-900 hover:text-gray-700"
									onClick={() => setOpen(false)}
								>
									Blog
								</NavLink>
								<NavLink
									to="/contactus"
									className="text-base font-medium text-gray-900 hover:text-gray-700"
									onClick={() => setOpen(false)}
								>
									Contact Us
								</NavLink>
							</div>
						</div>
					</div>
				</div>
			</motion.div>
		</>
	);
};

export default Navbar;
