import React from "react";
import { motion } from "framer-motion";
//import AnimatedText from "../animatedText/AnimatedText";
import "./Banner.css";

const Banner = () => {
	return (
		<motion.div
			className="mb-2 flex justify-center items-center my-8"
			initial={{ opacity: 0, x: "400" }}
			animate={{ opacity: 1, x: 0 }}
			transition={{
				duration: 3,
				delay: 1,
				ease: "easeInOut",
			}}
		>
			<div className="w-[90vw] h-[600px] lg:h-[700px]  flex flex-col justify-center items-center animate-gradient">
				<p
					className="px-8 sm:px-16 md:px-24 lg:py-28 lg:px-32 xl:px-40 text-base sm:text-xl md:text-2xl lg:text-4xl xl:text-4xl font-light text-justify text-white leading-relaxed"
					style={{ "font-family": "Bree Serif" }}
				>
					<p>
						<span className="text-black">Mirai</span>
						<span className="text-purple-800">Tech</span> is an Australian IT
						company that offers customized technology solutions to businesses of
						all sizes. Our experienced team of professionals, both onshore and
						offshore, provides a range of services, including web development,
						web design, digital marketing, database support, data warehousing,
						machine learning, and reporting.
					</p>
					<p className="text-white mt-3">
						{" "}
						At MiraiTech, we are passionate about creating technology solutions
						that help our clients achieve their goals. We specialize in
						delivering cutting-edge technology solutions that help our clients
						streamline their operations, increase their revenue, and stay ahead
						of the competition.  Our team of experts stays up to date with the
						latest industry trends and technologies to ensure that we deliver
						the most innovative and effective solutions.
					</p>
					{/* <span className="text-purple-900">
						{" "}
						Our commitment to quality and customer satisfaction is reflected in
						our successful track record of delivering projects on time and
						within budget. Let us help you take your business to the next level
						with our expert IT solutions.{" "}
					</span> */}
				</p>
			</div>
		</motion.div>
	);
};

export default Banner;
