import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const VisualisationPoints = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className="flex flex-col justify-center items-center bg-white">
			<Helmet>
				<title>Data Visualization and Reporting Services | MiraiTech</title>
				<meta
					name="description"
					content="MiraiTech offers customized data visualization and reporting solutions to help businesses gain insights from their data. Our services include data exploration and analysis, dashboard design and development, report creation and delivery, tool selection and implementation, and user training and support. Contact us to learn more."
				/>
			</Helmet>
			<div className="w-full md:h-96 overflow-hidden">
				<img
					src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2FData18.jpg?alt=media&token=327aeab8-64f6-43c5-ba97-af6183b77398"
					alt="m"
					className="px-2 w-full "
				/>
			</div>
			<div className="w-[97%] md:w-[70%]">
				<div className="mt-8 md:mt-16">
					<p className="text-3xl md:text-5xl font-bold text-purple-800 mb-8 md:mb-16 text-center">
						Our key services in Data visualisation and reporting
					</p>
				</div>
				<div>
					<ul className="text-justify text-gray-600 list-disc px-8">
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Data exploration and analysis
							</span>
							<span>:</span>
							<p className=" text-lg mt-2">
								This involves working with customers to understand their data
								and identify key insights and trends. Through data exploration
								and analysis, a data visualization and reporting company can
								help customers identify patterns in their data, and provide
								recommendations for further analysis or action.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Dashboard design and development
							</span>
							<span>:</span>
							<p className="mt-2 text-lg ">
								This involves creating custom dashboards that present key data
								in an easy-to-understand format. Dashboards can include charts,
								graphs, tables, and other visual elements to help customers
								quickly and easily understand key metrics and performance
								indicators
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Report creation and delivery
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								This involves creating custom reports that present data in a
								clear, concise manner. Reports can be delivered in a variety of
								formats, including PDF, Excel, and other common file types, and
								can be tailored to meet the specific needs and requirements of
								the customer.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Data visualization tool selection and implementation
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								This involves helping customers select and implement the right
								data visualization tools to meet their needs. A data
								visualization and reporting company can provide expertise on the
								latest tools and technologies, and help customers select the
								right tools to meet their specific needs.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								User training and support
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								This involves providing training and support to users to help
								them effectively utilize data visualization and reporting tools.
								By providing training and support, a data visualization and
								reporting company can help ensure that customers get the most
								value from their data visualization and reporting investments.
							</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default VisualisationPoints;
