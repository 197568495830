import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const MachineLearning = () => {
	const navigate = useNavigate();
	return (
		<div className="mt-24 px-4" id="machineL">
			<Helmet>
				<title>Machine Learning Solutions for Your Business | MiraiTech</title>
				<meta
					name="description"
					content=" MiraiTech offers innovative AI and machine learning solutions for businesses. Our services include data analysis, predictive modelling, natural language processing, image recognition, and deep learning. Contact us to learn how our solutions can streamline your operations and increase efficiency."
				/>
			</Helmet>
			<div className="md:grid md:grid-cols-2 md:gap-6 md:h-[800px] ">
				<div>
					<div className=" flex items-center justify-center md:justify-start  h-[80px]">
						<p className="text-3xl md:text-5xl font-bold text-purple-800">
							Machine Learning
						</p>
					</div>
					<div className="px-2 md:px-0 md:pr-10">
						<ul className="text-justify text-black">
							<li className="text-xl font-thin mb-1 ">
								MiraiTech specializes in providing innovative AI and machine
								learning solutions to businesses across industries. We leverage
								cutting-edge technology and a team of experienced professionals
								to deliver customized solutions that help our clients gain
								insights, streamline operations, and increase efficiency. Our
								services include data analysis, predictive modelling, natural
								language processing, image recognition, and deep learning.
							</li>
							<li className="text-xl font-thin mb-1 ">
								We work closely with our clients to understand their unique
								needs and objectives, and we use the latest tools and techniques
								to develop scalable, high-performance solutions that drive
								business success. Contact us to learn how our AI and machine
								learning solutions can benefit your organization.
							</li>
						</ul>
					</div>
					<p className="text-2xl md:text-3xl font-medium mt-4 px-1   mb-3 text-purple-800  ">
						Key services we provide
					</p>
					<ul className="list-disc ml-[35px] md:ml-[30px] mt-4 text-xl text-black">
						<li className=" mb-2">
							<span
								className="cursor-pointer hover:text-purple-800  hover:text-lg"
								onClick={() => navigate("/ourservices/machinelearning")}
							>
								Data Analysis
							</span>
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/machinelearning")}
						>
							Predictive Modelling
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/machinelearning")}
						>
							Natural Language Processing
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/machinelearning")}
						>
							Image Recognition
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/machinelearning")}
						>
							Deep Learning
						</li>
					</ul>
				</div>
				<div className="hidden md:block">
					<div className="flex justify-center items-center">
						<img
							src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2Fdata14.jpg?alt=media&token=519aa75d-c40b-46f2-91c8-8ff0862b0379"
							alt="Web Design"
							className="h-[700px] w-[600px] mt-4"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MachineLearning;
