import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const DbaSupport = () => {
	const navigate = useNavigate();
	return (
		<div className="mt-16 px-4" id="dbaS">
			<Helmet>
				<title>MiraiTech - Expertise in Database Development and Support</title>
				<meta
					name="description"
					content=" MiraiTech provides expert database development and support services, including database design and implementation, management, security and access control, backup and recovery, and tuning. Our team of professionals ensures that your database system is secure, efficient, and reliable. Contact us to learn how we can help your organization."
				/>
			</Helmet>
			<div className="md:grid md:grid-cols-2 md:gap-6 md:h-[800px] ">
				<div>
					<div className=" flex items-center justify-center md:justify-start  h-[80px]">
						<p className="text-3xl md:text-5xl font-bold text-purple-800">
							DBA Support
						</p>
					</div>
					<div className="px-2 md:px-0 md:pr-10">
						<ul className="text-justify text-black">
							<li className="text-xl font-thin mb-1 ">
								MiraiTech provides expertise in for managing and maintaining the
								customer’s databases. This includes the design, implementation,
								and management of the database systems to ensure that they are
								secure, efficient, and reliable. Every customer is unique and
								database administration can vary depending on the size of the
								organization and the complexity of its data systems, but we have
								expertise in catering customers of every size.
							</li>
							<li className="text-xl font-thin mb-1 ">
								We begin by conducting a thorough analysis of the customer's
								database system to identify any areas that may require tuning.
								We examine factors such as query performance, indexing
								strategies, caching mechanisms, and hardware resources to
								determine the most appropriate tuning strategy.
							</li>
						</ul>
					</div>
					<p className="text-2xl md:text-3xl font-medium mt-4 px-2   mb-3 text-purple-800  ">
						Key services we provide
					</p>

					<ul className="list-disc ml-[35px] md:ml-[30px] mt-4 text-xl text-black">
						<li className=" mb-2">
							<span
								className="cursor-pointer hover:text-purple-800  hover:text-lg"
								onClick={() => navigate("/ourservices/dbasupport")}
							>
								Database Design and Implementation
							</span>
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/dbasupport")}
						>
							Database Managemen
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/dbasupport")}
						>
							Security and Access Control
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/dbasupport")}
						>
							Backup and Recovery
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/dbasupport")}
						>
							Database Tuning
						</li>
					</ul>
				</div>
				<div className="hidden md:block">
					<div className="flex justify-center items-center">
						<img
							src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2FData01.jpg?alt=media&token=274114b2-c67a-44f6-bc88-6e9a30edd6ce"
							alt="Web Design"
							className="h-[700px] mt-4 w-[600px]"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DbaSupport;
