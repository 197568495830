import React from "react";
import ReactPlayer from "react-player";
import stockVideo from "../../assets/stockvideo.mp4";

const VideoComponent = () => {
	return (
		<div className="flex justify-center items-center">
			<div className="w-[97%]  mt-8 relative">
				<div className="aspect-w-16 aspect-h-9">
					<ReactPlayer
						url={stockVideo}
						width="100%"
						height="100%"
						controls={false}
						playing={true}
						loop={true}
						muted={true}
					/>
				</div>

				<p
					className="text-xl md:text-3xl lg:text-5xl xl:text-6xl text-center text-white font-bold absolute w-full md:w-[90%] bottom-6 md:bottom-10 lg:bottom-20 xl:bottom-20 md:left-6 lg:left-12 xl:left-12  md:opacity-80 tracking-wide"
					style={{ "font-family": "Bree Serif" }}
				>
					" Join Us in Driving Your Future Forward with Client-Centric Solutions
					"
				</p>
			</div>
		</div>
	);
};

export default VideoComponent;
