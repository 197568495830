import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const DataAnalytics = () => {
	const navigate = useNavigate();
	return (
		<div className="mt-16 px-4" id="dataA">
			<Helmet>
				<title>
					Data Analytics Services for Effective Business Decisions | MiraiTech
				</title>
				<meta
					name="description"
					content="MiraiTech's experienced team of data analysts provides customized data analytics solutions to help businesses gain valuable insights from their data. Our services include data management, data modeling, and descriptive, diagnostic, and prescriptive analytics. Learn more about our data analytics services at MiraiTech."
				/>
			</Helmet>
			<div className="md:grid md:grid-cols-2 md:gap-6 md:h-[800px] ">
				<div className="hidden md:block">
					<div className="flex justify-center items-center">
						<img
							src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2Fdata12.jpg?alt=media&token=15125728-b9ff-4f3f-b92a-6260c5a879ed"
							alt="Web Design"
							className="h-[700px] mt-4 w-[600px]"
						/>
					</div>
				</div>
				<div>
					<div className=" flex items-center justify-center md:justify-start  h-[80px]">
						<p className="text-3xl md:text-5xl font-bold text-purple-800">
							Data Analytics
						</p>
					</div>
					<div className="px-2 md:pr-10">
						<ul className="text-justify text-black">
							<li className="text-xl font-thin mb-1 ">
								At MiraiTech, we provide expert data analytics services to help
								our customers gain insights and value from their data. Our
								experienced team of data analysts works closely with customers
								to develop and implement customized data analytics solutions
								that are tailored to their specific needs and requirements.
							</li>

							<li className="text-xl font-thin mb-1">
								We use a wide range of data analytics technologies and
								techniques to analyze customer data and provide insights. This
								includes data mining, machine learning, statistical analysis,
								and visualization tools.
							</li>

							<li className="text-xl font-thin mb-1">
								In addition, we provide ongoing support and management services
								to ensure that the data analytics solution remains effective
								over time. Our team works closely with customers to evaluate the
								effectiveness of the data analytics solution and make
								adjustments as needed to ensure continued success.
							</li>
						</ul>
					</div>
					<p className="text-2xl md:text-3xl font-medium mt-4 px-2   mb-3 text-purple-800  ">
						Key services we provide
					</p>

					<ul className="list-disc ml-[30px] md:ml-[40px] mt-4 text-xl text-black">
						<li className=" mb-2">
							<span
								className="cursor-pointer hover:text-purple-800  hover:text-lg"
								onClick={() => navigate("/ourservices/dataanalytics")}
							>
								Data Management
							</span>
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/dataanalytics")}
						>
							Data Modelling
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/dataanalytics")}
						>
							Descriptive Analytics
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/dataanalytics")}
						>
							Diagnostic Analytics
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/dataanalytics")}
						>
							Prescriptive Analytics
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default DataAnalytics;
