import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const DataPlatform = () => {
	const navigate = useNavigate();
	return (
		<div className="mt-24 px-4">
			<Helmet>
				<title>
					Comprehensive Business Intelligence Services for Informed Decision
					Making | Our Data Platform Services
				</title>
				<meta
					name="description"
					content=" Our experienced professionals provide customized BI solutions for data analysis, visualization, warehousing, and reporting to help businesses improve efficiency, reduce costs, and increase revenue. Contact us to learn more about our data platform services."
				/>
			</Helmet>
			<div className="md:grid md:grid-cols-2 md:gap-6 md:h-[800px] " id="dataP">
				<div>
					<div className=" flex items-center justify-center md:justify-start  h-[80px]">
						<p className="text-3xl md:text-5xl font-bold text-purple-800">
							Data Platform
						</p>
					</div>
					<div className="px-2 md:px-0 md:pr-10">
						<ul className="text-justify text-black">
							<li className="text-xl font-thin mb-1 ">
								Our company provides comprehensive business intelligence (BI)
								services that help businesses gain insights and make informed
								decisions. We use cutting-edge BI tools and a team of
								experienced professionals to deliver customized solutions that
								address our clients' unique needs and objectives. Our services
								include data analysis, data visualization, data warehousing, and
								reporting.
							</li>
							<li className="text-xl font-thin mb-1 ">
								We work closely with our clients to understand their data
								requirements and provide solutions that are scalable, secure,
								and easy to use. Our BI solutions help businesses improve
								efficiency, reduce costs, and increase revenue. Contact us to
								learn how our BI services can benefit your organization.
							</li>
						</ul>
					</div>
					<p className="text-2xl md:text-3xl font-medium mt-4 px-2   mb-3 text-purple-800  ">
						Key services we provide
					</p>

					<ul className="list-disc ml-[30px] mt-4 text-xl text-black">
						<li className=" mb-2">
							<span
								className="cursor-pointer hover:text-purple-800  hover:text-lg"
								onClick={() => navigate("/ourservices/dataplatform")}
							>
								Data Analysis
							</span>
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/dataplatform")}
						>
							Data Visualization
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/dataplatform")}
						>
							Data Warehousing
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/dataplatform")}
						>
							Reporting
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/dataplatform")}
						>
							Consulting
						</li>
					</ul>
				</div>
				<div className="hidden md:block">
					<div className="flex justify-center items-center">
						<img
							src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2FData08.jpg?alt=media&token=25db0565-f165-48e5-87b8-da91b1517c4c"
							alt="Data Platform"
							className="w-[600px] h-[700px] mt-4"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DataPlatform;
