import React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const WebDesignPoints = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className="flex flex-col justify-center items-center bg-white">
			<Helmet>
				<title>
					Web Designing Services | MiraiTech | Graphic Design, Layout Design
				</title>
				<meta
					name="description"
					content="MiraiTech offers professional web designing services that focus on the visual and interactive aspects of a website or web application. Our web designers use tools such as Adobe Photoshop, Sketch, and Figma to create wireframes, prototypes, and high-fidelity designs. Learn about our web designing methodologies and process."
				/>
			</Helmet>
			<div className="w-full md:h-96 overflow-hidden ">
				<img
					src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2FData16.jpg?alt=media&token=499b3e89-6f69-47ad-b000-1c4215cc66db"
					alt="m"
					className="px-2"
				/>
			</div>
			<div className="w-[97%] md:w-[70%]">
				<div className="mt-8 md:mt-16">
					<p className="text-4xl md:text-5xl font-bold text-purple-800 mb-16 text-center">
						Methodologies
					</p>
				</div>
				<div>
					<ul className="text-justify text-gray-600 list-disc px-8">
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold">
								Planning
							</span>
							<span>:</span>
							<p className=" text-lg mt-2">
								This involves defining the project requirements and objectives,
								identifying the target audience, and creating a design plan that
								outlines the scope, timeline, and budget. In this stage, the
								design team may conduct research and gather feedback from
								stakeholders.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold">
								Wireframing
							</span>
							<span>:</span>
							<p className="mt-2 text-lg ">
								This involves creating a basic layout of the website or web
								application, usually in black and white. The wireframe serves as
								a blueprint that outlines the structure, navigation, and content
								of the site. This stage helps to establish the overall hierarchy
								of information and the user flow.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold">
								Prototyping
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								This involves creating a working prototype of the website or web
								application, usually using a design tool or software. The
								prototype serves as a mock-up of the final product, which allows
								the design team to test the design and gather feedback from
								stakeholders.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold">
								Visual design
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								This involves creating the look and feel of the website or web
								application, including the colour scheme, typography, and
								images. The visual design should be consistent with the brand
								identity and target audience.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold">
								Content creation
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								This involves creating or organizing the content for the website
								or web application, such as copy, images, and videos. The
								content should be optimized for search engines and aligned with
								the user's needs and preferences.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold">
								Testing
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								This involves testing the design for usability, accessibility,
								and responsiveness, to ensure that it works well on all devices
								and browsers.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold">
								Deployment
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								This involves delivering the final design to the development
								team, along with any design assets or documentation that they
								may need. The design team may also assist in the implementation
								of the design, to ensure that the final product is consistent
								with the design vision.
							</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default WebDesignPoints;
