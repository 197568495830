import React from "react";
import { Link } from "react-scroll";

const SideBar = () => {
	return (
		<div className="flex justify-start items-center bg-white h-[100vh] w-[17vw] pl-2">
			<div className="border border-gray-400 w-px h-96"></div>
			<div className="flex flex-col gap-4">
				<div className="flex gap-1">
					<div className=" border-b-2 border-gray-400 h-px w-6 translate-y-3"></div>
					<Link
						to="webD1"
						offset={-10}
						className="text-xl font-medium cursor-pointer hover:text-blue-800 hover:underline"
					>
						Web Development
					</Link>
				</div>
				<div className="flex gap-1">
					<div className=" border-b-2 border-gray-400 h-px w-6 translate-y-3"></div>
					<Link
						to="webDes1"
						offset={40}
						className="text-xl font-medium cursor-pointer hover:text-blue-800 hover:underline"
					>
						Web Designing
					</Link>
				</div>
				<div className="flex gap-1">
					<div className=" border-b-2 border-gray-400 h-px w-6 translate-y-3"></div>
					<Link
						to="dataA1"
						offset={60}
						className="text-xl font-medium cursor-pointer hover:text-blue-800 hover:underline"
					>
						Data Analytics
					</Link>
				</div>
				<div className="flex gap-1">
					<div className=" border-b-2 border-gray-400 h-px w-6 translate-y-3"></div>
					<Link
						to="dbaS1"
						offset={50}
						className="text-xl font-medium cursor-pointer hover:text-blue-800 hover:underline"
					>
						Database Support
					</Link>
				</div>
				<div className="flex gap-1">
					<div className=" border-b-2 border-gray-400 h-px w-6 translate-y-3"></div>
					<Link
						to="dataP1"
						offset={70}
						className="text-xl font-medium cursor-pointer hover:text-blue-800 hover:underline"
					>
						Data Platform
					</Link>
				</div>
				<div className="flex gap-1">
					<div className=" border-b-2 border-gray-400 h-px w-7 translate-y-3"></div>
					<Link
						to="visualD1"
						offset={70}
						className="text-xl font-medium cursor-pointer hover:text-blue-800 hover:underline"
					>
						Data Visualisation and Reporting
					</Link>
				</div>
				<div className="flex gap-1">
					<div className=" border-b-2 border-gray-400 h-px w-6 translate-y-3"></div>
					<Link
						to="digitalM1"
						offset={70}
						className="text-xl font-medium cursor-pointer hover:text-blue-800 hover:underline"
					>
						Digital Marketing
					</Link>
				</div>
				<div className="flex gap-1">
					<div className=" border-b-2 border-gray-400 h-px w-6 translate-y-3"></div>
					<Link
						to="machineL1"
						offset={70}
						className="text-xl font-medium cursor-pointer hover:text-blue-800 hover:underline"
					>
						Machine Learning
					</Link>
				</div>
			</div>
		</div>
	);
};

export default SideBar;
