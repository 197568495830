import React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const DbaSupportPoints = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className="flex flex-col justify-center items-center bg-white">
			<Helmet>
				<title>MiraiTech - Expertise in Database Development and Support</title>
				<meta
					name="description"
					content=" MiraiTech provides expert database development and support services, including database design and implementation, management, security and access control, backup and recovery, and tuning. Our team of professionals ensures that your database system is secure, efficient, and reliable. Contact us to learn how we can help your organization."
				/>
			</Helmet>
			<div className="w-full md:h-96 overflow-hidden">
				<img
					src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2Fdata09.jpg?alt=media&token=6c3e6afc-e51e-44e1-b8a0-692229c765cd"
					alt="m"
					className="px-2 w-full "
				/>
			</div>
			<div className="w-[97%] md:w-[70%]">
				<div className="mt-8 md:mt-16">
					<p className="text-3xl md:text-5xl font-bold text-purple-800 mb-8 md:mb-16 text-center">
						Our Expertise in Database Development and Support
					</p>
				</div>
				<div>
					<ul className="text-justify text-gray-600 list-disc px-8">
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Database Design and Implementation
							</span>
							<span>:</span>
							<p className=" text-lg mt-2">
								At our company, we offer expertise in database design and
								implementation to help our customers meet their data storage and
								processing requirements. Our team of experienced database
								administrators works closely with customers to understand their
								specific needs and design a database system that is optimized
								for their unique business needs
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Database Management
							</span>
							<span>:</span>
							<p className="mt-2 text-lg ">
								At our company, we provide comprehensive database management
								services to help our customers maintain efficient, secure, and
								reliable database systems. Our experienced team of database
								administrators works closely with customers to monitor and
								manage their database systems and ensure that they are operating
								at peak performance
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Security and Access Contro
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								At our company, we offer expert support for Security and Access
								Control in Database systems to ensure that our customers' data
								is protected from external threats and unauthorized access. Our
								experienced team of database administrators works closely with
								customers to implement security and access control measures that
								are tailored to their specific needs and requirements
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Backup and Recovery
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								At our company, we provide expert backup and recovery services
								to help our customers ensure that their data is protected in the
								event of a system failure, data corruption, or other issues. Our
								experienced team of database administrators works closely with
								customers to develop and implement robust backup and recovery
								procedures that are tailored to their specific needs and
								requirements.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Deployment
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								This involves uploading the website or web application to a web
								server and making it accessible to users. The project team may
								also configure domain name, SSL certificates, and other settings
								to ensure the website is secure and properly optimized for
								search engines.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Database Tuning
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								At our company, we provide expert database tuning services to
								help our customers ensure that their database systems are
								optimized for maximum performance and efficiency. Our
								experienced team of database administrators works closely with
								customers to identify and address performance issues and ensure
								that their database systems are operating at peak performance.
							</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default DbaSupportPoints;
