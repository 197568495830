import React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const DigitalMarketingPoints = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className="flex flex-col justify-center items-center bg-white">
			<Helmet>
				<title>
					Comprehensive Digital Marketing Services | Increase Your Online
					Presence
				</title>
				<meta
					name="description"
					content="  Our team of digital marketing experts work closely with customers to develop customized digital marketing strategies that align with their business objectives and target audience. Our range of digital marketing services includes SEO, PPC advertising, social media marketing, content marketing, and email marketing. Let us help you increase your online presence, generate leads, and drive business growth."
				/>
			</Helmet>
			<div className="w-full md:h-96 overflow-hidden">
				<img
					src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2Fdata10.jpg?alt=media&token=8f62f9f3-924b-4f87-b5fd-f92d3f4f82a7"
					alt="m"
					className="px-2 w-full "
				/>
			</div>
			<div className="w-[97%] md:w-[70%]">
				<div className="mt-8 md:mt-16">
					<p className="text-3xl md:text-5xl font-bold text-purple-800 mb-8 md:mb-16 text-center">
						Our range of key offerings
					</p>
				</div>
				<div>
					<ul className="text-justify text-gray-600 list-disc px-8">
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Search Engine Optimization (SEO)
							</span>
							<span>:</span>
							<p className=" text-lg mt-2">
								We help customers improve their website's visibility and search
								engine rankings by conducting in-depth keyword research,
								optimizing website content and structure, and developing a
								customized SEO strategy tailored to their specific needs.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Database Management
							</span>
							<span>:</span>
							<p className="mt-2 text-lg ">
								At our company, we provide comprehensive database management
								services to help our customers maintain efficient, secure, and
								reliable database systems. Our experienced team of database
								administrators works closely with customers to monitor and
								manage their database systems and ensure that they are operating
								at peak performance
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Pay-Per-Click (PPC) Advertising
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								We help customers drive targeted traffic to their website
								through PPC advertising campaigns on platforms such as Google
								Ads and social media platforms. Our team develops and implements
								customized PPC campaigns that are designed to maximize return on
								investment.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Backup and Recovery
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								At our company, we provide expert backup and recovery services
								to help our customers ensure that their data is protected in the
								event of a system failure, data corruption, or other issues. Our
								experienced team of database administrators works closely with
								customers to develop and implement robust backup and recovery
								procedures that are tailored to their specific needs and
								requirements.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Social Media Marketing
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								We help customers establish and grow their social media presence
								through targeted social media marketing campaigns on platforms
								such as Facebook, Twitter, LinkedIn, and Instagram. Our team
								develops and implements customized social media campaigns that
								are designed to engage with the target audience and drive
								business growth.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Content Marketing
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								We help customers create and distribute high-quality content
								such as blog posts, videos, and infographics that are designed
								to engage with the target audience and drive business growth.
								Our team develops and implements customized content marketing
								strategies that are tailored to the specific needs and goals of
								the customer.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Email Marketing
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								We help customers establish and grow their email list, and
								create targeted email marketing campaigns that are designed to
								engage with the target audience and drive business growth. Our
								team develops and implements customized email marketing
								campaigns that are designed to maximize open and click-through
								rates.
							</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default DigitalMarketingPoints;
