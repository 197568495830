import React from "react";
import Slider from "react-slick";

import { useNavigate } from "react-router-dom";

const CustomPrevArrow = (props) => (
	<button
		{...props}
		className="absolute top-1/2 left-0 z-10  transform -translate-y-1/2   text-black"
	>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			stroke-width="1.5"
			stroke="currentColor"
			className="w-12 h-12"
		>
			<path
				stroke-linecap="round"
				stroke-linejoin="round"
				d="M15.75 19.5L8.25 12l7.5-7.5"
			/>
		</svg>
	</button>
);

const CustomNextArrow = (props) => (
	<button
		{...props}
		className="absolute top-1/2 right-0  transform -translate-y-1/2 text-black"
	>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			stroke-width="1.5"
			stroke="currentColor"
			className="w-12 h-12"
		>
			<path
				stroke-linecap="round"
				stroke-linejoin="round"
				d="M8.25 4.5l7.5 7.5-7.5 7.5"
			/>
		</svg>
	</button>
);

const SlideNew = () => {
	var settings = {
		dots: false,
		infinite: true,
		speed: 4000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 6000,
		prevArrow: <CustomPrevArrow />,
		nextArrow: <CustomNextArrow />,
	};

	const navigate = useNavigate();

	return (
		<div className="flex justify-center items-center ">
			<div className="w-full lg:w-[97%] ">
				<Slider {...settings}>
					<div className="relative ">
						<img
							src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2FData02.jpg?alt=media&token=7439a576-bbb3-4792-b230-8c1e49217c9f"
							alt="j"
							className="h-[17rem] md:h-[30rem] lg:h-[800px] w-full "
						/>
						<div
							className="absolute h-[90%] lg:h-[38rem] lg:w-[30rem]  w-2/5 top-4 left-2 lg:p-4  bg-purple-400 bg-opacity-40 text-white text-justify p-2 lg:top-24 lg:left-4"
							style={{ "font-family": "Bree Serif" }}
						>
							<p
								className="text-left text-xs tracking-[1px] sm:text-center sm:text-2xl md:text-3xl lg:text-5xl font-medium lg:font-bold 
							lg:tracking-[2px] p-2 lg:p-4  opacity-80"
							>
								"Innovative web solutions to elevate your online presence and
								drive your business forward."
							</p>
							<p
								className="opacity-80 text-xs text-left mt-2 lg:mt-0 tracking-[1px] p-2 lg:p-0 font-medium sm:text-xl  sm:text-center md:text-2xl lg:text-4xl lg:pt-10 lg:text-left  "
								style={{ "font-family": "Bree Serif" }}
							>
								Customized web solutions, expertly-crafted.
							</p>
							<div className="w-full flex justify-end mt-2 text-xs md:text-base opacity-80  sm:mt-8">
								<button
									className="bg-white text-black w-auto px-2 lg:py-1 lg:w-32  rounded-md cursor-pointer hover:text-purple-800"
									onClick={() => navigate("/ourservices#webD")}
								>
									Read more...
								</button>
							</div>
						</div>
					</div>
					<div className="relative">
						<img
							src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2Fdata03.jpg?alt=media&token=ddee4916-afee-4b2a-8f5b-28dd719877b9"
							alt="j"
							className="h-[17rem] md:h-[30rem] lg:h-[800px] w-full "
						/>
						<div
							className="absolute h-[90%] lg:h-[38rem] lg:w-[30rem]  w-2/5 top-4 left-2 lg:p-4  bg-purple-400 bg-opacity-40 text-white text-justify p-2 lg:top-24 lg:left-4"
							style={{ "font-family": "Bree Serif" }}
						>
							<p
								className="text-left text-xs tracking-[1px] sm:text-center sm:text-2xl md:text-3xl lg:text-5xl font-medium lg:font-bold 
							lg:tracking-[2px] p-2 lg:p-4  opacity-80"
							>
								" Uncovering insights to drive success while Discovering the
								story in data - Who, What, When, Where, Why."
							</p>

							<p
								className="opacity-80 text-xs text-left mt-1 lg:mt-0 tracking-[1px] p-2 lg:p-0 font-medium sm:text-xl  sm:text-center md:text-2xl lg:text-4xl lg:pt-10 lg:text-left  "
								style={{ "font-family": "Bree Serif" }}
							>
								Insights, Reporting, Optimization.
							</p>
							<div className="w-full flex justify-end mt-2 lg:mt-0 text-xs md:text-base opacity-80  sm:mt-8">
								<button
									className="bg-white text-black w-auto px-2 lg:py-1 lg:w-32  rounded-md hover:text-purple-800"
									onClick={() => navigate("/ourservices#dataA")}
								>
									Read more...
								</button>
							</div>
						</div>
					</div>
					<div className="relative">
						<img
							src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2Fsocial-media-5217024.jpg?alt=media&token=013ed988-91bd-40b5-9cbb-dd894d0bd2f9"
							alt="j"
							className="h-[17rem] md:h-[30rem] lg:h-[800px] w-full "
						/>
						<div
							className="absolute h-[90%] lg:h-[38rem] lg:w-[30rem]  w-2/5 top-4 left-2 lg:p-4  bg-purple-400 bg-opacity-40 text-white text-justify p-2 lg:top-24 lg:left-4"
							style={{ "font-family": "Bree Serif" }}
						>
							<p
								className="text-left text-xs tracking-[1px] sm:text-center sm:text-2xl md:text-3xl lg:text-5xl font-medium lg:font-bold 
							lg:tracking-[2px] p-2 lg:p-4  opacity-80"
							>
								" Connecting your brand to the world with data-driven digital
								strategies. "
							</p>
							<p
								className="opacity-80 text-xs text-left mt-2 lg:mt-0 tracking-[1px] p-2 lg:p-0 font-medium sm:text-xl  sm:text-center md:text-2xl lg:text-4xl lg:pt-10 lg:text-left  "
								style={{ "font-family": "Bree Serif" }}
							>
								SEO, PPC, Social Media, Analytics, Content
							</p>

							<div className="w-full flex justify-end mt-2 text-xs md:text-base opacity-80  sm:mt-8">
								<button
									className="bg-white text-black w-auto px-2 lg:py-1 lg:w-32  rounded-md hover:text-purple-800"
									onClick={() => navigate("/ourservices#digitalM")}
								>
									Read more...
								</button>
							</div>
						</div>
					</div>

					<div className="relative">
						<img
							src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2Fdata05.jpg?alt=media&token=3722a25c-2a99-4dd8-a108-44ebe84369b2"
							alt="k"
							className="h-[17rem] md:h-[30rem] lg:h-[800px] w-full "
						/>
						<div
							className="absolute h-[90%] lg:h-[38rem] lg:w-[30rem]  w-2/5 top-4 left-2 lg:p-4  bg-purple-400 bg-opacity-40 text-white text-justify p-2 lg:top-24 lg:left-4"
							style={{ "font-family": "Bree Serif" }}
						>
							<p
								className="text-left text-xs tracking-[1px] sm:text-center sm:text-2xl md:text-3xl lg:text-5xl font-medium lg:font-bold 
							lg:tracking-[2px] p-2 lg:p-4  opacity-80"
							>
								" Unlocking the power of your data - Your trusted partner for
								expert DBA support "
							</p>
							<p
								className="opacity-80 text-xs text-left mt-2 lg:mt-0 tracking-[1px] p-2 lg:p-0 font-medium sm:text-xl  sm:text-center md:text-2xl lg:text-4xl lg:pt-10 lg:text-left  "
								style={{ "font-family": "Bree Serif" }}
							>
								24 X 7 Monitoring and Support
							</p>
							<div className="w-full flex justify-end mt-2 text-xs md:text-base opacity-80  sm:mt-8">
								<button
									className="bg-white text-black w-auto px-2 lg:py-1 lg:w-32  rounded-md hover:text-purple-800"
									onClick={() => navigate("/ourservices#dbaS")}
								>
									Read more...
								</button>
							</div>
						</div>
					</div>
					<div className="relative">
						<img
							src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2Fdata04.jpg?alt=media&token=b1971e36-84a3-4abd-beda-3deb7b27e66c"
							alt="k"
							className="h-[17rem] md:h-[30rem] lg:h-[800px] w-full "
						/>
						<div
							className="absolute h-[90%] lg:h-[38rem] lg:w-[30rem]  w-2/5 top-4 left-2 lg:p-4  bg-purple-400 bg-opacity-40 text-white text-justify p-2 lg:top-24 lg:left-4"
							style={{ "font-family": "Bree Serif" }}
						>
							<p
								className="text-left text-xs tracking-[1px] sm:text-center sm:text-2xl md:text-3xl lg:text-5xl font-medium lg:font-bold 
							lg:tracking-[2px] p-2 lg:p-4  opacity-80"
							>
								" Empowering businesses with data-driven insights through
								innovative machine learning solutions. "
							</p>
							<p
								className="opacity-80 text-xs text-left  lg:mt-0 tracking-[1px] p-2 lg:p-0 font-medium sm:text-xl  sm:text-left md:text-2xl lg:text-4xl lg:pt-10 lg:text-left  "
								style={{ "font-family": "Bree Serif" }}
							>
								Supervised, unsupervised, reinforcement, deep, transfer.
							</p>
							<div className="w-full flex justify-end  lg:mt-0 text-xs md:text-base opacity-80 md:-mt-6 ">
								<button
									className="bg-white text-black w-auto px-1 lg:px-2 lg:py-1 lg:w-32  rounded-md hover:text-purple-800"
									onClick={() => navigate("/ourservices#machineL")}
								>
									Read more...
								</button>
							</div>
						</div>
					</div>
					<div className="relative">
						<img
							src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2FiStock-1431719079.jpg?alt=media&token=fc4baea0-0bef-496d-b322-8f3bfbd1d620"
							alt="k"
							className="h-[17rem] md:h-[30rem] lg:h-[800px] w-full "
						/>
						<div
							className="absolute h-[90%] lg:h-[38rem] lg:w-[30rem]  w-2/5 top-4 left-2 lg:p-4  bg-purple-400 bg-opacity-40 text-white text-justify p-2 lg:top-24 lg:left-4"
							style={{ "font-family": "Bree Serif" }}
						>
							<p
								className="text-left text-xs tracking-[1px] sm:text-center sm:text-2xl md:text-3xl lg:text-5xl font-medium lg:font-bold 
							lg:tracking-[2px] p-2 lg:p-4  opacity-80"
							>
								"Refine your business with our Modern data platform services -
								where insights flow like oil "
							</p>
							<p
								className="opacity-80 text-xs text-left mt-2 lg:mt-0 tracking-[1px] p-2 lg:p-0 font-medium sm:text-xl  sm:text-center md:text-2xl lg:text-4xl lg:pt-10 lg:text-left  "
								style={{ "font-family": "Bree Serif" }}
							>
								Data Platform services, Reporting, Visualisation .
							</p>
							<div className="w-full flex justify-end mt-2 text-xs md:text-base opacity-80  sm:mt-8">
								<button
									className="bg-white text-black w-auto px-2 lg:py-1 lg:w-32  rounded-md hover:text-purple-800"
									onClick={() => navigate("/ourservices#dataP")}
								>
									Read more...
								</button>
							</div>
						</div>
					</div>
				</Slider>
			</div>
		</div>
	);
};

export default SlideNew;
