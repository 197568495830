import React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const MachineLearningPoints = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className="flex flex-col justify-center items-center bg-white">
			<Helmet>
				<title>Machine Learning Solutions for Your Business | MiraiTech</title>
				<meta
					name="description"
					content=" MiraiTech offers innovative AI and machine learning solutions for businesses. Our services include data analysis, predictive modelling, natural language processing, image recognition, and deep learning. Contact us to learn how our solutions can streamline your operations and increase efficiency."
				/>
			</Helmet>
			<div className="w-full md:h-96 overflow-hidden">
				<img
					src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2Fdata13.jpg?alt=media&token=2189bf74-5ba1-470c-a7ec-4287736d8431"
					alt="m"
					className="px-2 w-full "
				/>
			</div>
			<div className="w-[97%] md:w-[70%]">
				<div className="mt-8 md:mt-16">
					<p className="text-3xl px-2 md:px-0 md:text-5xl font-bold text-purple-800 mb-8 md:mb-16 text-center">
						Our range of key offerings
					</p>
				</div>
				<div>
					<ul className="text-justify text-gray-600 list-disc px-8">
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Data Analysis
							</span>
							<span>:</span>
							<p className=" text-lg mt-2">
								Our team of experts uses AI and machine learning to analyse
								large volumes of data to extract insights and identify patterns
								that help businesses make better decisions.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Predictive Modelling
							</span>
							<span>:</span>
							<p className="mt-2 text-lg ">
								We use AI and machine learning algorithms to develop predictive
								models that help businesses anticipate future outcomes and make
								data-driven decisions.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Natural Language Processing
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								Our NLP solutions use machine learning algorithms to analyse and
								understand human language, allowing businesses to automate
								customer service, sentiment analysis, and more.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Image Recognition
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								Our image recognition solutions use AI and machine learning
								algorithms to identify objects, people, and other visual
								elements in images and videos.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Deep Learning
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								We specialize in developing deep learning algorithms that can
								analyse complex data and learn from it over time, allowing
								businesses to improve performance and make better decisions.
							</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default MachineLearningPoints;
