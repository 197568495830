import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useEffect } from "react";
import { toast } from "react-toastify";

const ContactUs = () => {
	const form = useRef();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm(
				process.env.REACT_APP_EMAILJS_SERVICE_ID,
				"template_9ulhfkf",
				form.current,
				"recTSU_The86Q9f4z"
			)
			.then(
				(result) => {
					toast.success("Message was sent successfully");
				},
				(error) => {
					toast.error(error.text);
				}
			);

		e.target.reset();
	};

	return (
		<div className="py-10 bg-white" style={{ "font-family": "Bree Serif" }}>
			<h1 className="text-4xl text-center font-medium pb-2 md:pb-10 text-black">
				Contact Us
			</h1>
			<div className="lg:grid lg:grid-cols-2 m-8  justify-items-center">
				<div className="w-[97%] md:w-[80%]  mt-14">
					<form ref={form} onSubmit={sendEmail}>
						<div class="relative z-0 w-full mb-10 group ">
							<input
								type="text"
								name="username"
								class="block peer py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
								placeholder=" "
								pattern="^[A-Za-z ]{3,25}$"
								title="User Name should not have special characters or numbers and it should be 3-25 characters"
								required
							/>
							<label
								for="username"
								class="peer-focus:font-medium absolute text-base pl-4 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-10 scale-110 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-110 peer-focus:-ml-4 peer-focus:-translate-y-10 peer-focus:text-base"
							>
								Your Name
							</label>
							<span className="invisible  peer-focus:peer-invalid:visible text-red-600 mt-2">
								User Name should not have special characters or numbers and it
								should be 3-25 characters
							</span>
						</div>
						<div class="relative z-0 w-full mb-10 group">
							<input
								type="email"
								name="useremail"
								class="block peer py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
								placeholder=" "
								required
								pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
							/>
							<label
								for="useremail"
								class="peer-focus:font-medium absolute text-base pl-4 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-10 scale-110 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-110 peer-focus:-ml-4 peer-focus:-translate-y-10 peer-focus:text-base"
							>
								Your email address
							</label>
							<span className="invisible  peer-focus:peer-invalid:visible text-red-600 mt-2">
								please input valid email address
							</span>
						</div>
						<div class="relative z-0 w-full mb-10 group">
							<input
								type="text"
								name="subject"
								class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border  border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
								placeholder=" "
								required
							/>
							<label
								for="subject"
								class="peer-focus:font-medium absolute text-base pl-4 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-10 scale-110 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-110 peer-focus:-ml-4 peer-focus:-translate-y-10 peer-focus:text-base"
							>
								Subject
							</label>
						</div>
						<div class="relative z-0 w-full mb-10 group">
							<textarea
								rows="4"
								name="message"
								id="floating_repeat_password"
								class="block py-2.5 px-0 w-full text-sm border border-gray-300  text-gray-900 bg-transparent  appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
								placeholder=" "
								required
							/>
							<label
								for="message"
								class="peer-focus:font-medium absolute text-base pl-4 text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-10 scale-110 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-110 peer-focus:-ml-4 peer-focus:-translate-y-10 peer-focus:text-base"
							>
								Message
							</label>
						</div>

						<button
							type="submit"
							value="Send"
							class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
						>
							Submit
						</button>
					</form>
				</div>
				<div className="mt-16 md:mt-0">
					<div className="mt-12 flex flex-col gap-2  text-gray-900 p-2 md:p-10 rounded-md border-2 border-black">
						<p className="text-2xl md:text-4xl md:mb-10 font-medium text-center md:text-left text-purple-800">
							Our Contact Information
						</p>
						<p className="text-lg md:text-2xl font-medium">
							Fill the form or contact us via other channels below
						</p>
						<div className="flex flex-col gap-2 pt-2 md:pt-0 pb-8 md:pb-0 md:py-0 md:gap-8 md:my-8">
							<p className="flex gap-4   justify-start items-center">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									stroke="currentColor"
									className="w-4 h-4 md:w-8 md:h-8 text-purple-800"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
									/>
								</svg>

								<span className="text-base md:text-2xl font-medium">
									+61-419867768
								</span>
							</p>
							<p className="flex gap-1 md:gap-5  justify-start items-center">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									stroke="currentColor"
									className="w-4 h-4 md:w-8 md:h-8 text-purple-800"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
									/>
								</svg>

								<span className="text-base md:text-2xl font-medium">
									sales@miraitech.com.au
								</span>
							</p>
							<p className="flex gap-5 h-8  justify-start items-start">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									stroke="currentColor"
									className="w-4 h-4 md:w-8 md:h-8 text-purple-800"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
									/>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
									/>
								</svg>

								<span className="text-base md:text-2xl font-medium">
									Melbourne , Australia
								</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactUs;
