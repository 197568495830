import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const DigitalMarketing = () => {
	const navigate = useNavigate();
	return (
		<div className="mt-24 px-4" id="digitalM">
			<Helmet>
				<title>
					Comprehensive Digital Marketing Services | Increase Your Online
					Presence
				</title>
				<meta
					name="description"
					content="  Our team of digital marketing experts work closely with customers to develop customized digital marketing strategies that align with their business objectives and target audience. Our range of digital marketing services includes SEO, PPC advertising, social media marketing, content marketing, and email marketing. Let us help you increase your online presence, generate leads, and drive business growth."
				/>
			</Helmet>
			<div className="md:grid md:grid-cols-2 md:gap-6 md:h-[800px] ">
				<div className="hidden md:block">
					<div className="flex justify-center items-center">
						<img
							src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2FDiata11.jpg?alt=media&token=64b6be86-793c-4d88-acce-9720ddcd400a"
							alt="Web Design"
							className=" h-[700px] w-[600px] mt-4"
						/>
					</div>
				</div>
				<div>
					<div className=" flex items-center justify-center md:justify-start  h-[80px]">
						<p className="text-3xl md:text-5xl font-bold text-purple-800">
							Digital Marketing
						</p>
					</div>
					<div className="md:pr-10 px-2 md:px-0">
						<ul className="text-justify text-black">
							<li className="text-xl font-thin mb-1 ">
								At our company, we offer comprehensive digital marketing
								services to help our customers increase their online presence,
								generate leads, and drive business growth. Our team of digital
								marketing experts work closely with customers to develop
								customized digital marketing strategies that align with their
								business objectives and target audience.
							</li>
							<li className="text-xl font-thin mb-1 ">
								Throughout the digital marketing process, we prioritize open
								communication with our customers, providing regular updates and
								working closely with them to ensure that their specific digital
								marketing needs are being fully met. Our goal is to help our
								customers increase their online presence, generate leads, and
								drive business growth.
							</li>
						</ul>
					</div>
					<p className="text-2xl md:text-3xl font-medium mt-4 px-1   mb-3 text-purple-800  ">
						Key services we provide
					</p>

					<ul className="list-disc ml-[30px] mt-4 text-xl text-black">
						<li className=" mb-2">
							<span
								className="cursor-pointer hover:text-purple-800  hover:text-lg"
								onClick={() => navigate("/ourservices/digitalmarketing")}
							>
								Search Engine Optimization (SEO)
							</span>
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/digitalmarketing")}
						>
							Pay-Per-Click (PPC) Advertising
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/digitalmarketing")}
						>
							Social Media Marketing
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/digitalmarketing")}
						>
							Content Marketing
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/digitalmarketing")}
						>
							Email Marketing
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default DigitalMarketing;
