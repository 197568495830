import React, { useEffect } from "react";
import { motion } from "framer-motion";
import aiPic from "../../assets/aipic.jpg";
import { Helmet } from "react-helmet";

const Blog4 = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="flex justify-center items-center ">
			<Helmet>
				<title>
					Understanding Artificial Intelligence: Its Basics and Current
					Developments | Miraitech
				</title>
				<meta
					name="description"
					content="Discover the basics of artificial intelligence and its current scenario, including machine learning, deep learning, robotics, autonomous vehicles, and chatbots and virtual assistants. Learn how AI is evolving and its potential impact on society. Read our blog at Miraitech."
				/>
			</Helmet>
			<motion.div
				className="bg-[#F7F2E0] w-[60%]  my-6 py-12 px-8 flex flex-col justify-center items-center font-serif rounded-md shadow-lg shadow-blue-300"
				initial={{ opacity: 0, y: "-200", x: "-300" }}
				animate={{ opacity: 1, y: 0, x: 0 }}
				transition={{ duration: 3, delay: 2, type: "spring" }}
			>
				<p className="text-5xl font-bold mb-6  text-center">
					What is Artificial Intelligence and its current scenario ?
				</p>
				<img src={aiPic} alt="developer" className="h-[400px] mb-10" />
				<div className="text-xl">
					<p className="mb-4">
						Artificial Intelligence (AI) refers to the development of computer
						systems that can perform tasks that typically require human
						intelligence, such as visual perception, speech recognition,
						decision-making, and language translation.
					</p>
					<p className="mb-4">
						AI involves creating algorithms and computer programs that can learn
						from and make predictions or decisions based on large amounts of
						data. This is often done through the use of machine learning, a
						subset of AI that involves the development of algorithms that can
						learn from data without being explicitly programmed.
					</p>
					<p className="mb-4">
						There are many applications of AI, including image and speech
						recognition, natural language processing, robotics, and autonomous
						vehicles. AI has the potential to revolutionize many industries and
						to make significant advances in fields such as healthcare,
						transportation, finance, and manufacturing. However, it also raises
						important ethical and social questions about its impact on jobs,
						privacy, and human decision-making.
					</p>
					<p className="mb-4">
						There are many examples of Artificial Intelligence (AI) in use today
						across a wide range of industries and applications. Here are a few
						examples:
					</p>
					<ul className="list-decimal px-16 text-justify">
						<li className="mb-2">
							<span className="underline underline-offset-2">
								Virtual assistants
							</span>
							<span className="ml-1 mr-4">:</span>
							Virtual assistants like Siri and Alexa use natural language
							processing and machine learning algorithms to understand and
							respond to user requests, such as setting reminders or playing
							music.
						</li>
						<li className="mb-2">
							<span className="underline underline-offset-2">
								Image and speech recognition
							</span>
							<span className="ml-1 mr-4">:</span>
							AI algorithms can analyze and interpret images and speech, making
							it possible for applications like facial recognition software or
							speech-to-text software to work.
						</li>
						<li className="mb-2">
							<span className="underline underline-offset-2">
								Autonomous vehicles
							</span>
							<span className="ml-1 mr-4">:</span>
							Self-driving cars use AI to navigate roads and make decisions
							based on data from sensors and cameras.
						</li>
						<li className="mb-2">
							<span className="underline underline-offset-2">
								Fraud detection
							</span>
							<span className="ml-1 mr-4">:</span>
							AI algorithms can analyze large amounts of financial data to
							detect patterns of fraud and unusual behavior.
						</li>
						<li className="mb-2">
							<span className="underline underline-offset-2">
								Personalized marketing
							</span>
							<span className="ml-1 mr-4">:</span>
							AI algorithms can analyze user data to provide personalized
							recommendations for products and services, such as Amazon's
							product recommendations or Netflix's content recommendations.
						</li>
						<li className="mb-2">
							<span className="underline underline-offset-2">Healthcare</span>
							<span className="ml-1 mr-4">:</span>
							AI is being used in healthcare for diagnosis, personalized
							medicine, and drug development, such as using machine learning
							algorithms to analyze medical images or genetic data.
						</li>
						<li className="mb-2">
							<span className="underline underline-offset-2">Chatbots</span>
							<span className="ml-1 mr-4">:</span>
							Chatbots use natural language processing and machine learning
							algorithms to simulate human conversation and provide customer
							support or answer questions.
						</li>
					</ul>
					<p>
						These are just a few examples of how AI is being used today, but
						there are many other applications and use cases in development.
					</p>
				</div>
			</motion.div>
		</div>
	);
};

export default Blog4;
