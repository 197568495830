import React, { useEffect } from "react";
import { motion } from "framer-motion";
import WebDesign from "../../components/webDesign/WebDesign";

import DataAnalytics from "../../components/dataAnalytics/DataAnalytics";
import DbaSupport from "../../components/dbaSupport/DbaSupport";
import DigitalMarketing from "../../components/digitalMarketing/DigitalMarketing";
import MachineLearning from "../../components/machinelearning/MachineLearning";
import Visualisation from "../../components/visualisation/Visualisation";
import DataPlatform from "../../components/dataPlatform/DataPlatform";

import WebDevelopment from "../../components/webDevelopment/WebDevelopment";

import SideBar from "./SideBar";
import { Element } from "react-scroll";
import { Helmet } from "react-helmet";

const OurServices = () => {
	useEffect(() => {
		scrollToDiv();
	}, []);

	function scrollToDiv() {
		const hash = window.location.hash;
		if (hash !== "") {
			const section = document.querySelector(hash);
			if (section) {
				section.scrollIntoView({ behavior: "smooth", block: "start" });
			}
		} else {
			window.scrollTo({ top: 0, behavior: "smooth" });
		}
	}

	return (
		<div className=" overflow-hidden">
			<Helmet>
				<title>
					Miraitech - Web Design and Development Solutions in Australia
				</title>
				<meta
					name="description"
					content="Miraitech offers comprehensive solutions for web design and development, as well as specialized services in DBA support, machine learning, data analytics, digital marketing, data platform, artificial learning, and data visualization and reporting. Let us help your company succeed in the digital world with our expertise and innovative solutions."
				/>
				<meta
					name="keywords"
					content=" Web design , Web development , DBA support , Machine learning , Data analytics , Digital marketing  , Data platform , Artificial intelligence , Data visualization , Reporting"
				/>
			</Helmet>
			<div className="flex md:grid  md:grid-cols-[0.2fr,1fr] md:relative w-[100vw] ">
				<div className="hidden md:block md:fixed top-0 left-0  h-screen">
					<SideBar />
				</div>
				<div className="w-[100vw] md:w-[82vw] ml-0 md:ml-[17vw] ">
					<div className="grid grid-cols-1 w-full bg-white">
						<motion.div
							className="flex flex-col md:justify-start justify-end  items-start md:pl-6 h-[6rem] md:h-[100px] w-full mb-6 md:mt-16 "
							style={{ fontFamily: "Black Han Sans" }}
							initial={{ opacity: 0, y: "100vh" }}
							animate={{ opacity: 1, y: 0 }}
							transition={{
								duration: 5,
								type: "spring",
								stiffness: 600,
								damping: 10,
								delay: 3,
							}}
						>
							<div className="flex items-start">
								<span className="text-4xl md:text-6xl p-2 font-thin">
									Our Services
								</span>
							</div>
						</motion.div>
						<Element name="webD1">
							<WebDevelopment />
						</Element>
						<Element name="webDes1">
							<WebDesign />
						</Element>
						<Element name="dataA1">
							<DataAnalytics />
						</Element>

						<Element name="dbaS1">
							<DbaSupport />
						</Element>
						<Element name="digitalM1">
							<DigitalMarketing />
						</Element>
						<Element name="machineL1">
							<MachineLearning />
						</Element>

						<Element name="visualD1">
							<Visualisation />
						</Element>
						<Element name="dataP1">
							<DataPlatform />
						</Element>
					</div>
				</div>
			</div>
		</div>

		// <div className="bg-white">
		// 	<div className="grid grid-cols-[0.2fr,1fr] relative">
		// 		<div className="fixed top-0  bg-green-400 h-screen">
		// 			<SideBar />
		// 		</div>
		// 		<div className="w-full bg-white ">
		// 			<motion.div
		// 				className=" flex flex-col justify-start items-start pl-6 h-[100px] w-full mb-6  mt-16"
		// 				style={{ "font-family": "Black Han Sans" }}
		// 				initial={{ opacity: 0, y: "100vh" }}
		// 				animate={{ opacity: 1, y: 0 }}
		// 				transition={{
		// 					duration: 5,
		// 					type: "spring",
		// 					stiffness: 600,
		// 					damping: 10,
		// 					delay: 3,
		// 				}}
		// 			>
		// 				<div className="flex items-start ">
		// 					<span className="text-6xl p-2 font-thin ">Our Services</span>
		// 				</div>
		// 			</motion.div>
		// 			<WebDevelopment />

		// 			<WebDesign />
		// 			<DataAnalytics />
		// 			<DbaSupport />
		// 			<DigitalMarketing />
		// 			<MachineLearning />
		// 			<Visualisation />
		// 			<DataPlatform />
		// 		</div>
		// 	</div>
		// </div>
	);
};

export default OurServices;
