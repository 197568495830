import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const DataAnalyticsPoint = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="flex flex-col justify-center items-center bg-white">
			<Helmet>
				<title>
					Data Analytics Services for Effective Business Decisions | MiraiTech
				</title>
				<meta
					name="description"
					content="MiraiTech's experienced team of data analysts provides customized data analytics solutions to help businesses gain valuable insights from their data. Our services include data management, data modeling, and descriptive, diagnostic, and prescriptive analytics. Learn more about our data analytics services at MiraiTech."
				/>
			</Helmet>
			<div className="w-full md:h-96 overflow-hidden">
				<img
					src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2Fdata06.jpg?alt=media&token=7959eebc-eb90-4936-a613-24a7ce97a2cc"
					alt="m"
					className="px-2 w-full "
				/>
			</div>
			<div className="w-[97%] md:w-[70%]">
				<div className="mt-8 md:mt-16">
					<p className="text-3xl md:text-5xl font-bold text-purple-800 mb-16 text-center">
						Data Analytics key services
					</p>
				</div>
				<div>
					<ul className="text-justify text-gray-600 list-disc px-8">
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Data Management
							</span>
							<span>:</span>
							<p className=" text-lg mt-2">
								This involves the collection, storage, organization, and
								retrieval of customer data. A data analytics company may help
								customers manage their data more efficiently by providing tools
								and services for data cleaning, transformation, and integration.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Data Modelling
							</span>
							<span>:</span>
							<p className="mt-2 text-lg ">
								This involves the creation of data models that represent
								customer data in a structured format. These models can be used
								to help customers better understand their data, identify
								relationships between data points, and develop more accurate
								predictions and forecasts.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Descriptive Analytics
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								This involves analyzing historical data to identify patterns,
								trends, and other key insights. By applying statistical
								techniques and other methods, a data analytics company can help
								customers gain a deeper understanding of their data and identify
								opportunities for improvement.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Diagnostics Analytics
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								This involves using data analytics to understand why certain
								events occurred, by exploring the underlying causes and factors.
								For instance, a data analytics company might help a customer
								understand why sales dropped in a particular quarter, by
								examining data such as pricing, promotions, and customer
								behaviour.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Deployment
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								This involves uploading the website or web application to a web
								server and making it accessible to users. The project team may
								also configure domain name, SSL certificates, and other settings
								to ensure the website is secure and properly optimized for
								search engines.
							</p>
						</li>
						<li className="mb-6">
							<span className="text-xl underline underline-offset-4 font-bold text-black">
								Prescriptive Analytics
							</span>
							<span>:</span>
							<p className="mt-2 text-lg">
								This involves using data analytics to provide recommendations or
								actions based on insights gleaned from data. For instance, a
								data analytics company might help a customer optimize their
								supply chain by recommending the most cost-effective ordering
								and shipping strategies based on historical sales data.
							</p>
						</li>
					</ul>
				</div>
			</div>
			<div className="mt-2 text-lg w-[70%] text-justify text-gray-600">
				We are an expert IT company providing innovative solutions to businesses
				of all sizes. With our team of experienced professionals onshore and
				offshore, we offer customized software development, web development, and
				digital marketing services. We specialize in delivering cutting-edge
				technology solutions to help our clients streamline their operations,
				increase their revenue, and stay ahead of the competition. Our
				commitment to quality and customer satisfaction is reflected in our
				successful track record of delivering projects on time and within
				budget. Let us help you take your business to the next level with our
				expert IT solutions.
			</div>
		</div>
	);
};

export default DataAnalyticsPoint;
