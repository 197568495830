import React from "react";
import salesForcePic from "../../assets/salesforce.svg";
import amazon from "../../assets/amazon.svg";
import flutter from "../../assets/flutter.svg";
import gAnalytics from "../../assets/googleAnalytics.svg";
import firebase from "../../assets/firebase.svg";
import mongodb from "../../assets/mongodb.svg";
import oracle from "../../assets/oracle.svg";
import reactPic from "../../assets/reactPic.svg";
import sapPic from "../../assets/sap.svg";
import tableauPic from "../../assets/tableau.svg";
import azure from "../../assets/azure.svg";
import python from "../../assets/python.svg";
import ipython from "../../assets/python-icons.svg";
import gcloud from "../../assets/googlecloud.svg";
import sqlserver from "../../assets/sqlserver.svg";
import powerbi from "../../assets/powerbi.svg";

const Partners = () => {
	return (
		<div className="flex flex-col justify-center items-center pb-[100px]">
			<p
				className="text-4xl xl:text-5xl font-bold mb-12"
				style={{ "font-family": "Lusitana" }}
			>
				Technologies we specialize in
			</p>
			<div className="grid grid-cols-2 w-[80%] md:grid-cols-3 xl:grid-cols-4 xl:w-[70%] gap-7">
				<div className="flex justify-start xl:justify-start items-center gap-4 ">
					<div className="w-[30px] h-[30px] xl:w-[60px] xl:h-[60px] ">
						<img src={amazon} alt="amazon" />
					</div>
					<p className="text-xl xl:text-2xl  ">Amazon</p>
				</div>
				<div className="flex justify-start xl:justify-start items-center gap-4 ">
					<div className="w-[30px] h-[30px] xl:w-[60px] xl:h-[60px] ">
						<img src={gcloud} alt="googlecloud" />
					</div>
					<p className="text-xl xl:text-2xl  ">Google Cloud</p>
				</div>
				<div className="flex justify-start xl:justify-start items-center gap-4 ">
					<div className="w-[30px] h-[30px] xl:w-[60px] xl:h-[60px] ">
						<img src={azure} alt="azure" />
					</div>
					<p className="text-xl xl:text-2xl  ">Microsoft Azure</p>
				</div>
				<div className="flex justify-start xl:justify-start items-center gap-4 ">
					<div className="w-[30px] h-[30px] xl:w-[60px] xl:h-[60px] ">
						<img src={flutter} alt="flutter" />
					</div>
					<p className="text-xl xl:text-2xl  ">Flutter</p>
				</div>
				<div className="flex justify-start xl:justify-start items-center gap-4 ">
					<div className="w-[30px] h-[30px] xl:w-[60px] xl:h-[60px] ">
						<img src={gAnalytics} alt="google analytics" />
					</div>
					<p className="text-xl xl:text-2xl  ">Google Analytics</p>
				</div>
				<div className="flex justify-start xl:justify-start items-center gap-4 ">
					<div className="w-[30px] h-[30px] xl:w-[60px] xl:h-[60px] ">
						<img src={firebase} alt="firebase" />
					</div>
					<p className="text-xl xl:text-2xl  "> Google Firebase</p>
				</div>
				<div className="flex justify-start xl:justify-start items-center gap-4 ">
					<div className="w-[30px] h-[30px] xl:w-[60px] xl:h-[60px] ">
						<img src={mongodb} alt="mongodb" />
					</div>
					<p className="text-xl xl:text-2xl  ">mongoDb</p>
				</div>
				<div className="flex justify-start xl:justify-start items-center gap-4 ">
					<div className="w-[30px] h-[30px] xl:w-[60px] xl:h-[60px] ">
						<img src={oracle} alt="oracle" />
					</div>
					<p className="text-xl xl:text-2xl  ">Oracle</p>
				</div>
				<div className="flex justify-start xl:justify-start items-center gap-4 ">
					<div className="w-[30px] h-[30px] xl:w-[60px] xl:h-[60px] ">
						<img src={reactPic} alt="react" />
					</div>
					<p className="text-xl xl:text-2xl  ">React</p>
				</div>
				<div className="flex justify-start xl:justify-start items-center gap-4 ">
					<div className="w-[30px] h-[30px] xl:w-[60px] xl:h-[60px] ">
						<img src={salesForcePic} alt="salesforce" />
					</div>
					<p className="text-xl xl:text-2xl  ">Salesforce</p>
				</div>
				<div className="flex justify-start xl:justify-start items-center gap-4 ">
					<div className="w-[30px] h-[30px] xl:w-[60px] xl:h-[60px] ">
						<img src={sapPic} alt="sap" />
					</div>
					<p className="text-xl xl:text-2xl  ">SAP Sybase</p>
				</div>
				<div className="flex justify-start xl:justify-start items-center gap-4 ">
					<div className="w-[30px] h-[30px] xl:w-[60px] xl:h-[60px] ">
						<img src={tableauPic} alt="tableau" />
					</div>
					<p className="text-xl xl:text-2xl  ">Tableau</p>
				</div>

				<div className="flex justify-start xl:justify-start items-center gap-4 ">
					<div className="w-[30px] h-[30px] xl:w-[60px] xl:h-[60px] xl:mt-3 ">
						<img src={python} alt="R" />
					</div>
					<p className="text-xl xl:text-2xl  ">R</p>
				</div>
				<div className="flex justify-start xl:justify-start items-center gap-4 ">
					<div className="w-[30px] h-[30px] xl:w-[60px] xl:h-[60px] ">
						<img src={ipython} alt="python" />
					</div>
					<p className="text-xl xl:text-2xl  ">Python</p>
				</div>

				<div className="flex justify-start xl:justify-start items-center gap-4 ">
					<div className="w-[30px] h-[30px] xl:w-[60px] xl:h-[60px] ">
						<img src={sqlserver} alt="sql" />
					</div>
					<p className="text-xl xl:text-2xl  ">SQL</p>
				</div>
				<div className="flex justify-start xl:justify-start items-center gap-4 ">
					<div className="w-[30px] h-[30px] xl:w-[60px] xl:h-[60px] ">
						<img src={powerbi} alt="powerbi" />
					</div>
					<p className="text-xl xl:text-2xl  ">Power BI</p>
				</div>
			</div>
		</div>
	);
};

export default Partners;
