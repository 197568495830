import React from "react";

const ErrorPage = () => {
	return (
		<div className="bg-white h-[400px] w-full ">
			<div className="flex justify-center items-center">
				<div className="text-4xl w-[60%] h-[200px]  mt-[100px]">
					<p className="mb-8 text-purple-800 md:text-center">Awww... snap </p>
					<p className="text-3xl text-center text-red-800">
						The page you requested does not exist
					</p>
				</div>
			</div>
		</div>

		// <div className="bg-yellow-300 h-[400px] w-full ">
		// 	<div className="flex justify-center items-center ">
		// 		<div className="text-4xl w-[60%] h-[200px] bg-green-300">
		// 			<p>Awww... snap </p>
		// 			<p>The page you requested does not exist</p>
		// 		</div>
		// 	</div>
		// </div>
	);
};

export default ErrorPage;
