import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const WebDesign = () => {
	const navigate = useNavigate();
	return (
		<div className="mt-16 px-4" id="webDes">
			<Helmet>
				<title>
					Web Designing Services | MiraiTech | Graphic Design, Layout Design
				</title>
				<meta
					name="description"
					content="MiraiTech offers professional web designing services that focus on the visual and interactive aspects of a website or web application. Our web designers use tools such as Adobe Photoshop, Sketch, and Figma to create wireframes, prototypes, and high-fidelity designs. Learn about our web designing methodologies and process."
				/>
			</Helmet>
			<div className="md:grid md:grid-cols-2 md:h-[800px] ">
				<div>
					<div className=" flex items-center justify-center md:justify-start  h-[80px]">
						<p className="text-3xl  md:text-5xl font-bold text-purple-800">
							Web Designing
						</p>
					</div>
					<div className="px-2 md:px-0 md:pr-10">
						<ul className="text-justify text-black">
							<li className="text-xl font-thin mb-1 ">
								Web designing focuses on the visual and interactive aspects of a
								website or web application. It includes tasks such as graphic
								design, layout design, colour scheme selection, typography, and
								user interface design.
							</li>

							<li className="text-xl font-thin mb-1 ">
								Web designers work with tools such as Adobe Photoshop, Sketch,
								Figma, or In Vision to create wireframes, prototypes, and
								high-fidelity designs that reflect the needs and preferences of
								users.
							</li>
							<li className="text-base font-thin mb-1"></li>
						</ul>
					</div>
					<p className="text-2xl text-center md:text-left md:text-3xl font-medium mt-4  mb-3 text-purple-800  ">
						Methodologies we use in Website designing
					</p>
					<p className=" text-xl px-2 md:px-0 md:pr-10 text-black">
						The specific steps in web designing can vary depending on the
						project scope and methodology used. However, here is a general
						overview of the web designing process :
					</p>
					<ul className="list-disc ml-[30px] mt-4 text-xl text-black">
						<li className=" mb-2">
							<span
								className="cursor-pointer hover:text-purple-800  hover:text-lg"
								onClick={() => navigate("/ourservices/webdesigning")}
							>
								Planning
							</span>
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/webdesigning")}
						>
							Prototyping
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/webdesigning")}
						>
							Visual Design
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/webdesigning")}
						>
							Content Creation
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/webdesigning")}
						>
							Testing
						</li>
						<li
							className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
							onClick={() => navigate("/ourservices/webdesigning")}
						>
							Deployment
						</li>
					</ul>
				</div>
				<div className="hidden md:block">
					<div>
						<img
							src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2FData17.jpg?alt=media&token=504b6819-ab33-4c7c-a09c-46b1190419df"
							alt="Web Design"
							className="h-[700px] mt-4 w-[600px]"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WebDesign;
