import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Navbar from "./components/navbar/Navbar";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Blog2 from "./components/blog/Blog2";
import Blog1 from "./components/blog/Blog1";
import Blog3 from "./components/blog/Blog3";
import Blog4 from "./components/blog/Blog4";
import Blog5 from "./components/blog/Blog5";
import Blog6 from "./components/blog/Blog6";

import OurServices from "./pages/ourServices/OurServices";
import WebDesign from "./components/webDesign/WebDesign";
import WebDevelopmentPoints from "./pages/ourServices/WebDevelopmentPoints";
import Footer from "./components/footer/Footer";
import WebDesignPoints from "./components/webDesign/WebDesignPoints";
import DataAnalyticsPoint from "./components/dataAnalytics/DataAnalyticsPoint";
import DbaSupportPoints from "./components/dbaSupport/DbaSupportPoints";
import DigitalMarketingPoints from "./components/digitalMarketing/DigitalMarketingPoints";
import MachineLearningPoints from "./components/machinelearning/MachineLearningPoints";
import VisualisationPoints from "./components/visualisation/VisualisationPoints";
import ContactUs from "./pages/contactUs/ContactUs";
import ErrorPage from "./pages/errorPage/ErrorPage";
import { Helmet } from "react-helmet";
import PrivacyAndSecurity from "./pages/privacy/PrivacyAndSecurity";
import DataPlatformPoints from "./components/dataPlatform/DataPlatformPoints";

function App() {
	return (
		<>
			<div className="bg-white">
				<BrowserRouter>
					<Helmet>
						<title>
							Miraitech - Web Design and Development Solutions in Australia
						</title>
						<meta
							name="description"
							content="Miraitech offers comprehensive solutions for web design and development, as well as specialized services in DBA support, machine learning, data analytics, digital marketing, data platform, artificial learning, and data visualization and reporting. Let us help your company succeed in the digital world with our expertise and innovative solutions."
						/>
						<meta
							name="keywords"
							content=" Web design , Web development , DBA support , Machine learning , Data analytics , Digital marketing  , Data platform , Artificial intelligence , Data visualization , Reporting"
						/>
					</Helmet>
					<ToastContainer />
					<Navbar />
					<Routes>
						<Route path="/" element={<Home />} />

						<Route path="/blog/needofseo" element={<Blog2 />} />
						<Route path="/blog/reactisbest" element={<Blog1 />} />
						<Route path="/blog/benefitsofdatananlysis" element={<Blog3 />} />
						<Route path="/blog/artificialintelligence" element={<Blog4 />} />
						<Route path="/blog/digitaimarketingbenefits" element={<Blog5 />} />
						<Route path="/blog/machinelearningbenefits" element={<Blog6 />} />
						<Route path="/ourservices" element={<OurServices />} />
						<Route path="/webdesign" element={<WebDesign />} />
						<Route path="/contactus" element={<ContactUs />} />
						<Route
							path="/privacyandsecurity"
							element={<PrivacyAndSecurity />}
						/>

						<Route
							path="/ourservices/webdevelopment"
							element={<WebDevelopmentPoints />}
						/>
						<Route
							path="/ourservices/dataplatform"
							element={<DataPlatformPoints />}
						/>
						<Route
							path="/ourservices/webdesigning"
							element={<WebDesignPoints />}
						/>
						<Route
							path="/ourservices/dataanalytics"
							element={<DataAnalyticsPoint />}
						/>
						<Route
							path="/ourservices/dbasupport"
							element={<DbaSupportPoints />}
						/>
						<Route
							path="/ourservices/digitalmarketing"
							element={<DigitalMarketingPoints />}
						/>
						<Route
							path="/ourservices/machinelearning"
							element={<MachineLearningPoints />}
						/>
						<Route
							path="/ourservices/visualisation"
							element={<VisualisationPoints />}
						/>
						<Route path="/*" element={<ErrorPage />} />
					</Routes>
					<Footer />
				</BrowserRouter>
			</div>
		</>
	);
}

export default App;
