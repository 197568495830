import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const WebDevelopment = () => {
	const navigate = useNavigate();

	return (
		<div className="md:grid md:grid-cols-2 md:gap-1 md:h-[800px] " id="webD">
			<Helmet>
				<title>
					Web Development Services | Overview of Web Development Process
				</title>
				<meta
					name="description"
					content=" Our web development services involve building websites and web applications with technical expertise. We follow a general process that includes planning, design, development, testing, deployment, and maintenance. Read on for an overview of our web development process."
				/>
			</Helmet>
			<div className="hidden md:block w-full px-4 ">
				<div className="">
					<img
						src="https://firebasestorage.googleapis.com/v0/b/ecom-prashant.appspot.com/o/companyImage%2FDeveloping.png?alt=media&token=9963d22c-b4ef-4109-a25d-cdb917a2922d"
						alt="web development"
						className="mt-[10px] h-[700px] w-[600px]"
					/>
				</div>
			</div>
			<div className="w-full ">
				<div className=" flex items-center justify-center md:justify-start  h-[5rem]">
					<p className="text-3xl  md:text-5xl font-bold text-purple-800">
						Web Development
					</p>
				</div>
				<div className="px-4 md:px-0 md:pr-10">
					<ul className="text-justify text-black">
						<li className="text-xl font-thin mb-1 ">
							Web development involves the technical aspects of building
							websites and web applications.It includes tasks such as coding,
							programming, database development, and server configuration.{" "}
						</li>

						<li className="text-xl font-thin mb-1 ">
							Web developers use programming languages such as HTML, CSS,
							JavaScript, PHP, and others to build the functionality and
							features of a website or web application.They also work with
							frameworks and content management systems to speed up development
							and ensure high-quality code.
						</li>
					</ul>
				</div>
				<p className=" text-center md:text-left text-2xl font-medium mt-4  mb-3 text-purple-800  ">
					Methodologies we use in Website development
				</p>
				<p className=" text-xl px-4 md:px-0 md:pr-10 text-black">
					The specific steps in web development can vary depending on the
					project scope and methodology used. However, here is a general
					overview of the web development process :
				</p>
				<ul className="list-disc ml-[30px] mt-4 text-xl text-black">
					<li className=" mb-2">
						<span
							className="cursor-pointer hover:text-purple-800  hover:text-lg"
							onClick={() => navigate("/ourservices/webdevelopment")}
						>
							Planning
						</span>
					</li>
					<li
						className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
						onClick={() => navigate("/ourservices/webdevelopment")}
					>
						Design
					</li>
					<li
						className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
						onClick={() => navigate("/ourservices/webdevelopment")}
					>
						Development
					</li>
					<li
						className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
						onClick={() => navigate("/ourservices/webdevelopment")}
					>
						Testing
					</li>
					<li
						className="cursor-pointer hover:text-purple-800  hover:text-lg mb-2"
						onClick={() => navigate("/ourservices/webdevelopment")}
					>
						Deployment
					</li>
					<li
						className="cursor-pointer hover:text-purple-800  hover:text-lg "
						onClick={() => navigate("/ourservices/webdevelopment")}
					>
						Maintenance
					</li>
				</ul>
			</div>
		</div>
	);
};

export default WebDevelopment;
