import React, { useEffect } from "react";
import seopic from "../../assets/seopic.png";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

const Blog2 = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<div className="flex justify-center items-center ">
			<Helmet>
				<title>
					Why SEO is Essential for Businesses: Benefits and Strategies |
					Miraitech
				</title>
				<meta
					name="description"
					content="Discover why SEO is essential for businesses to establish a strong online presence, attract more traffic, and stay ahead of competitors. Learn about the benefits of SEO and strategies for optimizing your website. Read our blog at Miraitech."
				/>
			</Helmet>
			<motion.div
				className="bg-[#F7F2E0] w-[60%]  my-6 py-8 px-6 flex flex-col justify-center items-center font-serif rounded-md shadow-lg shadow-blue-300"
				initial={{ opacity: 0, y: "-200", x: "-300" }}
				animate={{ opacity: 1, y: 0, x: 0 }}
				transition={{ duration: 3, delay: 2, type: "spring" }}
			>
				<p className="text-5xl font-bold mb-6 ">Why do you need SEO ?</p>
				<img src={seopic} alt="seo" className="h-[400px] mb-10" />
				<div className="text-xl">
					<p className="mb-4">
						SEO stands for Search Engine Optimization, which refers to the
						practice of optimizing a website or web page so that it ranks higher
						in the search engine results pages (SERPs) of search engines such as
						Google, Bing, and Yahoo.A business owner needs SEO for several
						reasons:
					</p>
					<ul className="list-decimal px-16 text-justify">
						<li className="mb-2">
							<span className="underline underline-offset-2">
								Increased visibility
							</span>
							<span className="ml-1 mr-4">:</span>
							With the majority of consumers using search engines to find
							information, products, and services, it's essential for businesses
							to have a strong online presence. SEO helps to increase a
							business's visibility in the search engine results pages (SERPs),
							which can lead to more website traffic and potential customers.
						</li>
						<li className="mb-2">
							<span className="underline underline-offset-2">
								Improved user experience
							</span>
							<span className="ml-1 mr-4">:</span>
							SEO involves optimizing a website's structure, content, and user
							experience to improve its ranking and usability. This can help to
							create a more user-friendly website that is easy to navigate and
							provides a better overall experience for visitors.
						</li>
						<li className="mb-2">
							<span className="underline underline-offset-2">
								Targeted traffic
							</span>
							<span className="ml-1 mr-4">:</span>
							SEO can help to attract targeted traffic to a website by
							optimizing it for specific keywords and phrases related to a
							business's products or services. This can lead to higher
							conversion rates, as visitors are more likely to be interested in
							what the business has to offer.
						</li>
						<li className="mb-2">
							<span className="underline underline-offset-2">
								Competitive advantage
							</span>
							<span className="ml-1 mr-4">:</span>
							In today's digital landscape, businesses that don't have a strong
							online presence are likely to be left behind by their competitors.
							SEO can help to level the playing field and provide a competitive
							advantage, particularly for small businesses that are competing
							against larger, more established companies.
						</li>
					</ul>
					<p>
						Overall, SEO is an essential component of any successful digital
						marketing strategy, and a business owner who invests in SEO is
						likely to see significant benefits in terms of increased visibility,
						targeted traffic, and improved user experience.
					</p>
				</div>
			</motion.div>
		</div>
	);
};

export default Blog2;
